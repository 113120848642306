import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_RESET, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_RESET, FORGOT_PASSWORD_SUCCESS, GET_COUNTRY_LIST_ERROR, GET_COUNTRY_LIST_RESET, GET_COUNTRY_LIST_SUCCESS, GET_PROFILE_ERROR, GET_PROFILE_RESET, GET_PROFILE_SUCCESS, LOGIN_ERROR, LOGIN_RESET, LOGIN_SUCCESS, LOGOUT_ERROR, LOGOUT_RESET, LOGOUT_SUCCESS, UPDATE_FORGOT_PASSWORD_ERROR, UPDATE_FORGOT_PASSWORD_RESET, UPDATE_FORGOT_PASSWORD_SUCCESS, VERIFY_OTP_ERROR, VERIFY_OTP_RESET, VERIFY_OTP_SUCCESS } from "../Constant";

let initialState = {
  login: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  logout: {
    error: false,
    errors: null,
    success: false,
  },
  getProfile: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  getCountryList: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  changePassword: {
    error: false,
    errors: null,
    success: false,
  },
  forgotPass: {
    error: false,
    errors: null,
    success: false,
  },
  verifyOtp: {
    error: false,
    errors: null,
    success: false,
  },
  updateForgotPass: {
    error: false,
    errors: null,
    success: false,
  },
};



export default function authReducer(state = initialState, action) {
  switch (action.type) {

    // login
    case LOGIN_SUCCESS:
      return {
        ...state, login: { ...state.login, success: true, error: false, errors: null, data: action.data, },
      };

    case LOGIN_ERROR:
      return {
        ...state, login: { ...state.login, success: false, error: true, errors: action.msg, },
      };

    case LOGIN_RESET:
      return {
        ...state, login: { ...state.login, success: false, error: false, errors: null },
      };

    // logout
    case LOGOUT_SUCCESS:
      return {
        ...state, logout: { ...state.logout, success: true, error: false, errors: null, data: action.data, },
      };

    case LOGOUT_ERROR:
      return {
        ...state, logout: { ...state.logout, success: false, error: true, errors: action.msg, },
      };

    case LOGOUT_RESET:
      return {
        ...state, logout: { ...state.logout, success: false, error: false, errors: null },
      };


    // profile
    case GET_PROFILE_SUCCESS:
      return {
        ...state, getProfile: { ...state.getProfile, success: true, error: false, errors: null, data: action.data, },
      };

    case GET_PROFILE_ERROR:
      return {
        ...state, getProfile: { ...state.getProfile, success: false, error: true, errors: action.msg, },
      };

    case GET_PROFILE_RESET:
      return {
        ...state, getProfile: { ...state.getProfile, success: false, error: false, errors: null },
      };


    // countryList
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state, getCountryList: { ...state.getCountryList, success: true, error: false, errors: null, data: action.data, },
      };

    case GET_COUNTRY_LIST_ERROR:
      return {
        ...state, getCountryList: { ...state.getCountryList, success: false, error: true, errors: action.msg, },
      };

    case GET_COUNTRY_LIST_RESET:
      return {
        ...state, getCountryList: { ...state.getCountryList, success: false, error: false, errors: null },
      };



    // CHANGE PASSWORD
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state, changePassword: { ...state.changePassword, success: true, error: false, errors: null },
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state, changePassword: { ...state.changePassword, success: false, error: true, errors: action.msg, },
      };

    case CHANGE_PASSWORD_RESET:
      return {
        ...state, changePassword: { ...state.changePassword, success: false, error: false, errors: null },
      };


    //  forgot pass

    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPass: { ...state.forgotPass, success: true, errors: null, error: false, } };

    case FORGOT_PASSWORD_ERROR:
      return { ...state, forgotPass: { ...state.forgotPass, success: false, error: true, errors: action.msg, } };

    case FORGOT_PASSWORD_RESET:
      return { ...state, forgotPass: { ...state.forgotPass, success: false, error: false, errors: null, }, };

    //  verify otp

    case VERIFY_OTP_SUCCESS:
      return { ...state, verifyOtp: { ...state.verifyOtp, success: true, error: false, errors: null, }, };

    case VERIFY_OTP_ERROR:
      return { ...state, verifyOtp: { ...state.verifyOtp, success: false, error: true, errors: action.msg, } };

    case VERIFY_OTP_RESET:
      return {
        ...state, verifyOtp: { ...state.verifyOtp, success: false, error: false, errors: null, },
      };

    //  updateForgotpass

    case UPDATE_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state, updateForgotPass: { ...state.updateForgotPass, success: true, error: false, errors: null, },
      };

    case UPDATE_FORGOT_PASSWORD_ERROR:
      return {
        ...state, updateForgotPass: { ...state.updateForgotPass, success: false, error: true, errors: action.msg, },
      };

    case UPDATE_FORGOT_PASSWORD_RESET:
      return {
        ...state, updateForgotPass: { ...state.updateForgotPass, success: false, error: false, errors: null, },
      };

    default:
      return state;
  }
}