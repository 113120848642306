import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import videoUrl from "../assets/production ID_5106444.mp4";
import { login } from "../Services/Actions/authAction";
import { LOGIN_RESET } from "../Services/Constant";
// import { getProfile, login } from "../userStore/action/authAction";
// import { LOGIN_RESET } from "../userStore/common/constant";
// import { useCookies } from "react-cookie";

const LoginPage = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [state, setState] = useState({
    username: "",
    password: "",
    remeber: true,
  });

  const navigate = useNavigate();
  // const [rememberMe, setRememberMe] = useState(cookies.rememberMe || false);
  const [error, setError] = useState({
    username: false,
    password: false,
  });

  useEffect(() => {
    const details = document.cookie.split(`details=`)[1]?.split(";")[0];
    const _details = !!details ? JSON.parse(details) : null;
    if (!!_details) {
      setState((prev) => ({
        ...prev,
        username: _details.email,
        password: _details.password,
      }));
    }
  }, [document.cookie]);

  const handleChange = (e) => {
    const { name, id, value } = e.target;

    setState((prevstate) => ({
      ...prevstate,
      [id]: value,
    }));

    switch (name) {
      case "username":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            username: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            username: false,
          }));
        }
        break;
      case "password":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            password: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;
      default:
        break;
    }
  };
  const handleLogin = () => {
    if (state.username === "") {
      setError((prevState) => ({
        ...prevState,
        username: "Required",
      }));
    }
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Required",
      }));
    }
    if (state.username !== "" && state.password !== "") {
      props.login({
        details: {
          email: state.username,
          password: state.password,
        },
        remeber: state.remeber,
      });
      setSpinner(true);
    }
  };

  useEffect(() => {
    if (props.loginReducer.success) {
      setSpinner(false);
      navigate("/");
      props.resetLogin()
    } else if (props.loginReducer.error) {
      setSpinner(false);
      toast.error(props.loginReducer.errors.error_message);
      props.resetLogin()
    }
  }, [props.loginReducer]);

  return (
    <div className="auth-bg">
      <video src={videoUrl} autoPlay muted loop />

      <div className="auth-form">
        <div className="mb-4 d-flex justify-content-center">
          <img
            style={{ cursor: "pointer" }}
            src={"/images/headerLogo.png"}
            className="img-fluid logo"
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <h2 className="text-center">Login</h2>
        <Form autoComplete="off">
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Username</Form.Label>
            <Form.Control
              type="text"
              value={state.username}
              name="username"
              id="username"
              onChange={handleChange}
              placeholder=""
            />
            {error.username && (
              <span className="form-error">{error.username}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Password</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={showPass ? "text" : "password"}
                value={state.password}
                onChange={handleChange}
                name="password"
                id="password"
                placeholder=""
                style={{ paddingRight: "40px" }}
              />
              <i
                className={`eye-btn position-absolute fa-regular ${
                  showPass ? "fa-eye-slash" : "fa-regular fa-eye"
                }`}
                onClick={() => setShowPass(!showPass)}
                style={{ cursor: "pointer", top: "17px", right: "20px" }}
              ></i>
            </div>
            {error.password && (
              <span className="form-error">{error.password}</span>
            )}
          </Form.Group>
          <div className="text-end">
            <Link to="/forgot-password" className="text-primary fw-semibold">
              Forgot password?
            </Link>
          </div>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Remember Me"
              checked={state.remeber}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  remeber: e.target.checked,
                }));
              }}
            />
          </Form.Group>
          <Button
            type="button"
            onClick={handleLogin}
            disabled={spinner}
            className="mt-5"
          >
            {spinner ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Login</>
            )}
          </Button>
        </Form>
        {/* <div className="text-center small border-top mt-4 pt-3">
          Not registered yet?{" "}
          <Link to="/register" className="text-primary ms-2 fw-semibold">
            {" "}
            Sign up
          </Link>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginReducer: state.authReducer.login,
});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  // getProfile: () => dispatch(getProfile()),
  resetLogin: () => dispatch({ type: LOGIN_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
