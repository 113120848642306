import { ADD_STOCK_LIST_ERROR, ADD_STOCK_LIST_LOADING, ADD_STOCK_LIST_SUCCESS, CONFIRM_LIST_ERROR, CONFIRM_LIST_RESET, CONFIRM_LIST_SUCCESS, DIAMOND_RESET_ERROR, DIAMOND_RESET_SUCCESS, GET_ALL_MASTERS_LIST_ERROR, GET_ALL_MASTERS_LIST_LOADING, GET_ALL_MASTERS_LIST_SUCCESS, GET_STOCK_LIST_ERROR, GET_STOCK_LIST_LOADING, GET_STOCK_LIST_SUCCESS, HOLD_LIST_ERROR, HOLD_LIST_RESET, HOLD_LIST_SUCCESS, INVALID_STONE_LIST_ERROR, INVALID_STONE_LIST_RESET, INVALID_STONE_LIST_SUCCESS, RESET_ADD_STOCK_LIST, RESET_DIAMOND_RESET, RESET_GET_ALL_MASTERS_LIST, RESET_GET_STOCK_LIST, RESET_SAVE_CSV, RESET_STOCK_LIST_STATUS, RESET_UPDATE_STOCK_LIST, RESET_UPLOAD_CSV, RESET_UPLOAD_HISTORY, RESET_UPLOAD_HISTORY_DOWNLOAD, SAVE_CSV_ERROR, SAVE_CSV_LOADING, SAVE_CSV_SUCCESS, STOCK_LIST_STATUS_ERROR, STOCK_LIST_STATUS_SUCCESS, UPDATE_STOCK_LIST_ERROR, UPDATE_STOCK_LIST_LOADING, UPDATE_STOCK_LIST_SUCCESS, UPLOAD_CSV_ERROR, UPLOAD_CSV_LOADING, UPLOAD_CSV_SUCCESS, UPLOAD_HISTORY_DOWNLOAD_ERROR, UPLOAD_HISTORY_DOWNLOAD_SUCCESS, UPLOAD_HISTORY_ERROR, UPLOAD_HISTORY_SUCCESS } from "../Constant";

let initialState = {
  invalidStone: {
    error: false,
    success: false,
    data: [],
  },
  holdStone: {
    error: false,
    success: false,
    data: [],
  },
  confirmStone: {
    error: false,
    success: false,
    data: [],
  },
  getUploadHistoryList: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  downloadHistory: {
    error: false,
    errors: null,
    success: false,
  },
  getAllMastersList: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  StockList: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  addStockList: {
    loading: false,
    data: null,
    error: false,
    success: false,
    msg: null
  },
  updateStockList: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  statusStockList: {
    error: false,
    success: false,
  },
  uploadCsvFile: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  saveCsvFile: {
    loading: false,
    data: null,
    error: false,
    success: false,
  },
  resetDiamond: {
    data: null,
    error: false,
    success: false,
  },
};



export default function masterListReducer(state = initialState, action) {
  switch (action.type) {
    //  invalid
    case INVALID_STONE_LIST_SUCCESS:
      return { ...state, invalidStone: { ...state.invalidStone, success: true, error: false, data: action.data }, };

    case INVALID_STONE_LIST_ERROR:
      return { ...state, invalidStone: { ...state.invalidStone, success: false, error: true, }, };

    case INVALID_STONE_LIST_RESET:
      return { ...state, invalidStone: { ...state.invalidStone, success: false, error: false } };



    //  hold
    case HOLD_LIST_SUCCESS:
      return { ...state, holdStone: { ...state.holdStone, success: true, error: false, data: action.data }, };

    case HOLD_LIST_ERROR:
      return { ...state, holdStone: { ...state.holdStone, success: false, error: true, }, };

    case HOLD_LIST_RESET:
      return { ...state, holdStone: { ...state.holdStone, success: false, error: false } };


    //  confirm
    case CONFIRM_LIST_SUCCESS:
      return { ...state, confirmStone: { ...state.confirmStone, success: true, error: false, data: action.data }, };

    case CONFIRM_LIST_ERROR:
      return { ...state, confirmStone: { ...state.confirmStone, success: false, error: true, }, };

    case CONFIRM_LIST_RESET:
      return { ...state, confirmStone: { ...state.confirmStone, success: false, error: false } };




    //  get invalid stone list
    case UPLOAD_HISTORY_SUCCESS:
      return { ...state, getUploadHistoryList: { ...state.getUploadHistoryList, loading: false, error: false, success: true, data: action.response.data } };

    case UPLOAD_HISTORY_ERROR:
      return { ...state, getUploadHistoryList: { ...state.getUploadHistoryList, loading: false, error: true, success: false, data: null } };

    case RESET_UPLOAD_HISTORY:
      return { ...state, getUploadHistoryList: { ...state.getUploadHistoryList, loading: false, error: false, success: false } };


    case UPLOAD_HISTORY_DOWNLOAD_SUCCESS:
      return { ...state, downloadHistory: { ...state.downloadHistory, success: true, error: false, errors: null }, };

    case UPLOAD_HISTORY_DOWNLOAD_ERROR:
      return { ...state, downloadHistory: { ...state.downloadHistory, success: false, error: true, errors: action.msg } };

    case RESET_UPLOAD_HISTORY_DOWNLOAD:
      return { ...state, downloadHistory: { ...state.downloadHistory, success: false, error: false, errors: null } };


    //  get all masters
    case GET_ALL_MASTERS_LIST_LOADING:
      return { ...state, getAllMastersList: { ...state.getAllMastersList, loading: true, error: false, success: false } };

    case GET_ALL_MASTERS_LIST_SUCCESS:
      return { ...state, getAllMastersList: { ...state.getAllMastersList, loading: false, error: false, success: true, data: action.response.data } };

    case GET_ALL_MASTERS_LIST_ERROR:
      return { ...state, getAllMastersList: { ...state.getAllMastersList, loading: false, error: true, success: false, data: null } };

    case RESET_GET_ALL_MASTERS_LIST:
      return { ...state, getAllMastersList: { ...state.getAllMastersList, loading: false, error: false, success: false } };

    //  get Stock list
    case GET_STOCK_LIST_LOADING:
      return { ...state, StockList: { ...state.StockList, loading: true, error: false, success: false } };

    case GET_STOCK_LIST_SUCCESS:
      return { ...state, StockList: { ...state.StockList, loading: false, error: false, success: true, data: action.response.data } };

    case GET_STOCK_LIST_ERROR:
      return { ...state, StockList: { ...state.StockList, loading: false, error: true, success: false, data: null } };

    case RESET_GET_STOCK_LIST:
      return { ...state, StockList: { ...state.StockList, loading: false, error: false, success: false } };

    //  add Stock list
    case ADD_STOCK_LIST_LOADING:
      return { ...state, addStockList: { ...state.addStockList, loading: true, error: false, success: false } };

    case ADD_STOCK_LIST_SUCCESS:
      return { ...state, addStockList: { ...state.addStockList, loading: false, error: false, success: true } };

    case ADD_STOCK_LIST_ERROR:
      return { ...state, addStockList: { ...state.addStockList, loading: false, error: true, success: false, msg: action.errorMsg } };

    case RESET_ADD_STOCK_LIST:
      return { ...state, addStockList: { ...state.addStockList, loading: false, error: false, success: false, msg: null } };


    //  update Stock list
    case UPDATE_STOCK_LIST_LOADING:
      return { ...state, updateStockList: { ...state.updateStockList, loading: true, error: false, success: false } };

    case UPDATE_STOCK_LIST_SUCCESS:
      return { ...state, updateStockList: { ...state.updateStockList, loading: false, error: false, success: true } };

    case UPDATE_STOCK_LIST_ERROR:
      return { ...state, updateStockList: { ...state.updateStockList, loading: false, error: true, success: false, msg: action.errorMsg } };

    case RESET_UPDATE_STOCK_LIST:
      return { ...state, updateStockList: { ...state.updateStockList, loading: false, error: false, success: false } };

    //  status Stock list
    case STOCK_LIST_STATUS_SUCCESS:
      return { ...state, statusStockList: { ...state.statusStockList, error: false, success: true } };

    case STOCK_LIST_STATUS_ERROR:
      return { ...state, statusStockList: { ...state.statusStockList, error: true, success: false } };

    case RESET_STOCK_LIST_STATUS:
      return { ...state, statusStockList: { ...state.statusStockList, error: false, success: false } };

    //  upload csv
    case UPLOAD_CSV_LOADING:
      return { ...state, uploadCsvFile: { ...state.uploadCsvFile, loading: true, error: false, success: false } };

    case UPLOAD_CSV_SUCCESS:
      return { ...state, uploadCsvFile: { ...state.uploadCsvFile, loading: false, error: false, success: true, data: action.response.data } };

    case UPLOAD_CSV_ERROR:
      return { ...state, uploadCsvFile: { ...state.uploadCsvFile, loading: false, error: true, success: false, data: null } };

    case RESET_UPLOAD_CSV:
      return { ...state, uploadCsvFile: { ...state.uploadCsvFile, loading: false, error: false, success: false } };

    //  save csv
    case SAVE_CSV_LOADING:
      return { ...state, saveCsvFile: { ...state.saveCsvFile, loading: true, error: false, success: false } };

    case SAVE_CSV_SUCCESS:
      return { ...state, saveCsvFile: { ...state.saveCsvFile, loading: false, error: false, success: true, data: action.response.data } };

    case SAVE_CSV_ERROR:
      return { ...state, saveCsvFile: { ...state.saveCsvFile, loading: false, error: true, success: false, data: null } };

    case RESET_SAVE_CSV:
      return { ...state, saveCsvFile: { ...state.saveCsvFile, loading: false, error: false, success: false } };




    //  reset diamond

    case DIAMOND_RESET_SUCCESS:
      return { ...state, resetDiamond: { ...state.resetDiamond, loading: false, error: false, success: true, data: action.response.data } };

    case DIAMOND_RESET_ERROR:
      return { ...state, resetDiamond: { ...state.resetDiamond, loading: false, error: true, success: false, data: null } };

    case RESET_DIAMOND_RESET:
      return { ...state, resetDiamond: { ...state.resetDiamond, loading: false, error: false, success: false } };

    default:
      return state;
  }
}