
// const baseUrl = "http://192.168.0.21:8002/vendor";
const baseUrl = "https://server.delightdiamonds.com/vendor";

export const login_Url = `${baseUrl}/login`
export const logout_Url = `${baseUrl}/logout`


export const forgotPassword_URL = baseUrl + "/forgotpassword"
export const verifyOtp_URL = baseUrl + "/verifyotp"
export const resetPassword_URL = baseUrl + "/updatepassword"

export const dashboard_Url = `${baseUrl}/dashboard`
export const profile_Url = `${baseUrl}/profile`
export const countryList_Url = `${baseUrl}/country`
export const changePassword_Url = `${baseUrl}/change-password`

export const invalidStone_Url = `${baseUrl}/invalid?diamond_type=`
export const holdStone_Url = `${baseUrl}/hold`
export const confirmStone_Url = `${baseUrl}/confirm`




export const uploadHistory_Url = `${baseUrl}/history`
export const uploadHistory_download_Url = `${baseUrl}/history`


export const getStockList_Url = `${baseUrl}/product`
export const addStockList_Url = `${baseUrl}/product/create`
export const updateStockList_Url = `${baseUrl}/product/update`
export const statusStockList_Url = `${baseUrl}/product/status`


export const getAllMasterList_Url = `${baseUrl}/product/master`

export const importFile_Url = `${baseUrl}/product/import`
export const saveFile_Url = `${baseUrl}/product/import`


export const diamondReset_Url = `${baseUrl}/product/reset`