import axios from "axios";
import { addStockList_Url, confirmStone_Url, diamondReset_Url, getAllMasterList_Url, getStockList_Url, holdStone_Url, importFile_Url, invalidStone_Url, saveFile_Url, statusStockList_Url, updateStockList_Url, uploadHistory_Url, uploadHistory_download_Url } from "../api_sheet";
import { ADD_STOCK_LIST_ERROR, ADD_STOCK_LIST_LOADING, ADD_STOCK_LIST_SUCCESS, CONFIRM_LIST_ERROR, CONFIRM_LIST_SUCCESS, DIAMOND_RESET_ERROR, DIAMOND_RESET_SUCCESS, GET_ALL_MASTERS_LIST_ERROR, GET_ALL_MASTERS_LIST_LOADING, GET_ALL_MASTERS_LIST_SUCCESS, GET_STOCK_LIST_ERROR, GET_STOCK_LIST_LOADING, GET_STOCK_LIST_SUCCESS, HOLD_LIST_ERROR, HOLD_LIST_SUCCESS, INVALID_STONE_LIST_ERROR, INVALID_STONE_LIST_RESET, INVALID_STONE_LIST_SUCCESS, SAVE_CSV_ERROR, SAVE_CSV_LOADING, SAVE_CSV_SUCCESS, STOCK_LIST_STATUS_ERROR, STOCK_LIST_STATUS_SUCCESS, UPDATE_STOCK_LIST_ERROR, UPDATE_STOCK_LIST_LOADING, UPDATE_STOCK_LIST_SUCCESS, UPLOAD_CSV_ERROR, UPLOAD_CSV_LOADING, UPLOAD_CSV_SUCCESS, UPLOAD_HISTORY_DOWNLOAD_ERROR, UPLOAD_HISTORY_DOWNLOAD_SUCCESS, UPLOAD_HISTORY_ERROR, UPLOAD_HISTORY_SUCCESS } from "../Constant";
import moment from "moment";

export const invalidStoneList = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${invalidStone_Url}${id}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp);
        dispatch({
          type: INVALID_STONE_LIST_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: INVALID_STONE_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: INVALID_STONE_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const holdStoneList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: holdStone_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp);
        dispatch({
          type: HOLD_LIST_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: HOLD_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: HOLD_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const confirmStoneList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: confirmStone_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CONFIRM_LIST_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: CONFIRM_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: CONFIRM_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const getUploadHistoryList = () => {
  return (dispatch) => {

    const requestOptions = {
      method: "GET",
      url: uploadHistory_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {

        dispatch({
          type: UPLOAD_HISTORY_SUCCESS,
          response: {
            data: resp.data.data.result
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPLOAD_HISTORY_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: UPLOAD_HISTORY_ERROR,
              errors: validationError
            });
          }
      });
  };
}


export const downloadUploadHistory = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${uploadHistory_download_Url}/${id}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      'responseType': 'blob'
    }
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `DD_UPLOAD_HISTORY_${moment().format('DDMMyyyy')}_${Math.floor(100000 + Math.random() * 9000)}.xlsx`
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: UPLOAD_HISTORY_DOWNLOAD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPLOAD_HISTORY_DOWNLOAD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPLOAD_HISTORY_DOWNLOAD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const getStockList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_STOCK_LIST_LOADING,
    });
    const requestOptions = {
      method: "GET",
      url: getStockList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {

        dispatch({
          type: GET_STOCK_LIST_SUCCESS,
          response: {
            data: resp.data.data.result
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_STOCK_LIST_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_STOCK_LIST_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const addStockList = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_STOCK_LIST_LOADING,
    });
    const requestOptions = {
      method: "POST",
      url: addStockList_Url,
      data: details,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_STOCK_LIST_SUCCESS,
        })
      })
      .catch((error) => {
        let data = error.response;
        const msg = error.response.data.error.error_message
        if (!error.response) {
          dispatch({
            type: ADD_STOCK_LIST_ERROR
          });

        } else
          if (data.status === 422) {
            dispatch({
              type: ADD_STOCK_LIST_ERROR
            });
          } else {
            dispatch({
              type: ADD_STOCK_LIST_ERROR,
              errorMsg: msg
            });
          }
      });
  };
}

export const updateStockList = ({ details, id }) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STOCK_LIST_LOADING,
    });
    const requestOptions = {
      method: "POST",
      url: `${updateStockList_Url}/${id}`,
      data: details,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_STOCK_LIST_SUCCESS,
        })
      })
      .catch((error) => {
        let data = error.response;
        const msg = error.response.data.error.error_message
        if (!error.response) {
          dispatch({
            type: UPDATE_STOCK_LIST_ERROR,
          });

        } else
          if (data.status === 422) {
            dispatch({
              type: UPDATE_STOCK_LIST_ERROR,
            });
          }
          else {
            dispatch({
              type: ADD_STOCK_LIST_ERROR,
              errorMsg: msg
            });
          }
      });
  };
}

export const changeStockListStatus = ({ id, details }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${statusStockList_Url}/${id}`,
      data: details,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: STOCK_LIST_STATUS_SUCCESS,
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: STOCK_LIST_STATUS_ERROR,
          });

        } else
          if (data.status === 422) {
            dispatch({
              type: STOCK_LIST_STATUS_ERROR,
            });
          }
      });
  };
}

export const getAllMastersList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_MASTERS_LIST_LOADING,
    });
    const requestOptions = {
      method: "GET",
      url: getAllMasterList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ALL_MASTERS_LIST_SUCCESS,
          response: {
            data: resp.data.data.result
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ALL_MASTERS_LIST_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_ALL_MASTERS_LIST_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const uploadCsvFile = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_CSV_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: importFile_Url,
      data: details
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPLOAD_CSV_SUCCESS,
          response: {
            data: resp.data.data
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPLOAD_CSV_ERROR
          });

        } else
          if (data.status === 422) {
            dispatch({
              type: UPLOAD_CSV_ERROR
            });
          } else if (data.status === 500) {
            dispatch({
              type: UPLOAD_CSV_ERROR
            });
          }
      });
  };
}

export const saveCsvFile = ({ details, id, diamond_type }) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_CSV_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: `${saveFile_Url}/${id}`,
      data: {
        diamond_type: diamond_type,
        data: JSON.stringify([...details])
      }
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SAVE_CSV_SUCCESS,
          response: {
            data: resp.data.data.result
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SAVE_CSV_ERROR
          });

        } else
          if (data.status === 422) {
            dispatch({
              type: SAVE_CSV_ERROR
            });
          }
      });
  };
}

export const resetDiamondList = (id) => {
  return (dispatch) => {

    const requestOptions = {
      method: "GET",
      url: `${diamondReset_Url}/${id}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };

    axios(requestOptions)
      .then((resp) => {

        dispatch({
          type: DIAMOND_RESET_SUCCESS,
          response: {
            data: resp.data.data.result
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DIAMOND_RESET_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DIAMOND_RESET_ERROR,
              errors: validationError
            });
          }
      });
  };
}
