import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getAllMastersList,
  saveCsvFile,
  uploadCsvFile,
} from "../../Services/Actions/masterListAction";
import { toast } from "react-toastify";
import { RESET_UPLOAD_CSV } from "../../Services/Constant";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import styles from "./stock.module.css";
import {
  BootstrapTable,
  SearchField,
  TableHeaderColumn,
} from "react-bootstrap-table";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from "reactstrap";
import _ from "lodash";
import classNames from "classnames";

function UploadCsv(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [file, setFile] = useState({
    fileValue: "",
    fileObject: null,
  });

  const [diamondType, setDiamondType] = useState("");
  const [error, setError] = useState(false);
  const [details, setDetails] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [openDownloadOption, setOpenDownloadOption] = useState(false);

  const [state, setState] = useState([]);

  useEffect(() => {
    if (!props.getAllMastersListReducer.success) {
      props.getAllMastersList();
    }
  }, []);

  useEffect(() => {
    if (props.getAllMastersListReducer.success) {
      const data = props.getAllMastersListReducer.data;
      setDetails(data);
    }
  }, [props.getAllMastersListReducer]);

  useEffect(() => {
    if (props.uploadCsvFileReducer.success) {
      const data = props.uploadCsvFileReducer.data.result;
      if (parseInt(diamondType) === 1) {
        const list = data.map((e, i) => {
          return {
            srNo: i + 1,
            ...e,
            stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
            cert_no: !!e.cert_no && e.cert_no !== "" ? e.cert_no : "N/A",
            cert_type:
              !!e.cert_type && e.cert_type !== "" ? e.cert_type : "N/A",
            cert_url: !!e.cert_url && e.cert_url !== "" ? e.cert_url : "N/A",
            carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
            shape_id: !!e.shape_id && e.shape_id !== "" ? e.shape_id : "N/A",
            color_id: !!e.color_id && e.color_id !== "" ? e.color_id : "N/A",
            colors_id:
              !!e.colors_id && e.colors_id !== "" ? e.colors_id : "N/A",
            overtone_id:
              !!e.overtone_id && e.overtone_id !== "" ? e.overtone_id : "N/A",
            intensity_id:
              !!e.intensity_id && e.intensity_id !== ""
                ? e.intensity_id
                : "N/A",
            clarity_id:
              !!e.clarity_id && e.clarity_id !== "" ? e.clarity_id : "N/A",
            polish_id:
              !!e.polish_id && e.polish_id !== "" ? e.polish_id : "N/A",
            symmetry_id:
              !!e.symmetry_id && e.symmetry_id !== "" ? e.symmetry_id : "N/A",
            fluorescence_id:
              !!e.fluorescence_id && e.fluorescence_id !== ""
                ? e.fluorescence_id
                : "N/A",
            rapo_rate:
              !!e.rapo_rate && e.rapo_rate !== "" ? e.rapo_rate : "N/A",
            discount: !!e.discount && e.discount !== "" ? e.discount : "N/A",
            rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
            amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
            table_per:
              !!e.table_per && e.table_per !== "" ? e.table_per : "N/A",
            depth_per:
              !!e.depth_per && e.depth_per !== "" ? e.depth_per : "N/A",
            country: !!e.country && e.country !== "" ? e.country : "N/A",
            length: !!e.length && e.length !== "" ? e.length : "N/A",
            width: !!e.width && e.width !== "" ? e.width : "N/A",
            height: !!e.height && e.height !== "" ? e.height : "N/A",
          };
        });
        console.log("list", list);
        setState([...list]);
      } else if (parseInt(diamondType) === 2) {
        const list = data.map((e, i) => {
          return {
            srNo: i + 1,
            ...e,
            stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
            carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
            shape_id: !!e.shape_id && e.shape_id !== "" ? e.shape_id : "N/A",
            color_id: !!e.color_id && e.color_id !== "" ? e.color_id : "N/A",
            colors_id:
              !!e.colors_id && e.colors_id !== "" ? e.colors_id : "N/A",
            overtone_id:
              !!e.overtone_id && e.overtone_id !== "" ? e.overtone_id : "N/A",
            intensity_id:
              !!e.intensity_id && e.intensity_id !== ""
                ? e.intensity_id
                : "N/A",
            clarity_id:
              !!e.clarity_id && e.clarity_id !== "" ? e.clarity_id : "N/A",
            rapo_rate:
              !!e.rapo_rate && e.rapo_rate !== "" ? e.rapo_rate : "N/A",
            discount: !!e.discount && e.discount !== "" ? e.discount : "N/A",
            rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
            amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
            country: !!e.country && e.country !== "" ? e.country : "N/A",
            city: !!e.city && e.city !== "" ? e.city : "N/A",
            growth_type:
              !!e.growth_type && e.growth_type !== "" ? e.growth_type : "N/A",
          };
        });
        setState([...list]);
      }
    } else if (props.uploadCsvFileReducer.error) {
      setLoading(false);
      toast.error("Upload file same as sample file...");
    }
  }, [props.uploadCsvFileReducer]);

  useEffect(() => {
    if (state.length !== 0 && props.uploadCsvFileReducer.success) {
      setIsOpenModal(true);
      setLoading(false);
      props.resetCsvFile();
    } else if (state.length === 0 && props.uploadCsvFileReducer.success) {
      setIsOpenModal(false);
      props.resetCsvFile();
      navigate("/upload-diamonds");
      toast.success("Data uploaded successfully...");
    }
  }, [state]);

  useEffect(() => {
    if (props.saveFileReducer.success) {
      const data = props.saveFileReducer.data;
      if (data.length === 0) {
        setIsOpenModal(false);
        navigate("/upload-diamonds");
        toast.success("Data uploaded successfully...");
      } else {
        if (parseInt(diamondType) === 1) {
          setIsOpenModal(true);
          const list = data.map((e, i) => {
            return {
              srNo: i + 1,
              ...e,
            };
          });
          setState([...list]);
        } else if (parseInt(diamondType) === 2) {
          setIsOpenModal(true);
          const list = data.map((e, i) => {
            return {
              srNo: i + 1,
              ...e,
            };
          });
          setState([...list]);
        }
      }
      setLoading(false);
    } else if (props.saveFileReducer.error) {
      setLoading(false);
    }
  }, [props.saveFileReducer]);

  const handleInput = (e) => {
    setDiamondType(e.target.value);
    if (e.target.value === "") {
      setError("* Please select diamond type");
    } else {
      setError(false);
    }
  };
  const customCell = (cell, value) => {
    return <div className={`d-flex justify-content-between`}>{cell}</div>;
  };

  const renderSizePerPageDropDown = (props) => {
    const limits = [];
    props.sizePerPageList.forEach((limit) => {
      limits.push(
        <DropdownItem
          key={limit}
          onClick={() => props.changeSizePerPage(limit)}
        >
          {limit}
        </DropdownItem>
      );
    });
    return (
      <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
        <DropdownToggle color="default" caret>
          {props.currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>{limits}</DropdownMenu>
      </Dropdown>
    );
  };

  const createCustomSearchField = (props) => {
    return <SearchField className="mb-sm-5 me-1" placeholder="Search" />;
  };

  const handleDownload = (e, type) => {
    const link = document.createElement("a");
    link.href =
      parseInt(type) === 1
        ? "https://server.delightdiamonds.com/sample/NATURAL_DIAMOND.xlsx"
        : parseInt(type) === 2
        ? "https://server.delightdiamonds.com/sample/LABGROWN_DIAMONDS.xlsx"
        : "";
    link.download =
      parseInt(type) === 1
        ? "NATURAL_DIAMOND.xlsx"
        : parseInt(type) === 2
        ? "LABGROWN_DIAMONDS.xlsx"
        : "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    searchField: createCustomSearchField,
    sizePerPageDropDown: renderSizePerPageDropDown,
  };

  const handleUpdatedCell = (updatedObject, key, newValue, position) => {
    const _list = [...state];
    _list[position.rowIndex] = updatedObject;

    setState([..._list]);
  };

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-end align-items-center">
        <div
          className="position-relative d-inline-block"
          onMouseEnter={() => setOpenDownloadOption(true)}
          onMouseLeave={() => setOpenDownloadOption(false)}
        >
          <div
            className="border p-2 "
            style={{
              background: "#C2B362",
              color: "#ffffff",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Download Sample files
            <i className="fa fa-angle-down ms-3"></i>
          </div>
          {openDownloadOption && (
            <div
              className="position-absolute"
              style={{
                top: "37px",
                width: "195px",
                overflowWrap: "break-word",
                border: "1px solid black",
                boxShadow: "3px 3px 8px 0px rgba(0,0,0,0.56)",
                borderRadius: "3px",
              }}
            >
              {!!details &&
                Object.keys(details?.DiamondType).map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="py-2 ps-2 hover-div"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(event) => handleDownload(event, e)}
                    >
                      {details?.DiamondType[e]}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col lg={5} md={8} className="mb-3">
              <label className="form-label">Diamond Type</label>
              <select
                className="form-select"
                value={diamondType}
                onChange={handleInput}
              >
                <option value="">Select Type</option>
                {!!details &&
                  Object.keys(details?.DiamondType).map((e, i) => {
                    return (
                      <option value={e} key={i}>
                        {details?.DiamondType[e]}
                      </option>
                    );
                  })}
              </select>
            </Col>
          </Row>
          <Row
            style={
              !!error || diamondType === ""
                ? {
                    opacity: "0.5",
                    pointerEvents: "none",
                  }
                : {}
            }
          >
            <Col lg={5} md={8}>
              <div>
                <div className="mb-4">
                  <label className="form-label">Upload CSV File</label>
                  <input
                    type="file"
                    className="form-control"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    // value={file.fileValue}
                    onChange={(e) => {
                      setFile({
                        fileValue: e.target.value,
                        fileObject: e.target.files[0],
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-start align-items-center mt-2 flex-wrap">
            <div
              className={classNames("py-2 mt-2 px-3  me-3 border rounded", {
                "bg-primary text-white": file.fileValue !== "",
                "bg-light text-muted": file.fileValue === "",
              })}
              style={{
                cursor: file.fileValue === "" ? "no-drop" : "pointer",
              }}
              onClick={() => {
                if (file.fileValue !== "") {
                  props.uploadCsvFile({
                    excel: file.fileObject,
                    diamond_type: diamondType,
                    upload_type: 1,
                  });
                  setLoading(true);
                }
              }}
            >
              <div>Replace All existing Inventory</div>
            </div>

            <div
              className={classNames("py-2 mt-2 px-3  me-3 border rounded", {
                "bg-secondary text-white": file.fileValue !== "",
                "bg-light text-muted": file.fileValue === "",
              })}
              style={{
                cursor: file.fileValue === "" ? "no-drop" : "pointer",
              }}
              onClick={() => {
                if (file.fileValue !== "") {
                  props.uploadCsvFile({
                    excel: file.fileObject,
                    diamond_type: diamondType,
                    upload_type: 2,
                  });
                  setLoading(true);
                }
              }}
            >
              <div>Update existing Inventory</div>
            </div>
          </div>
        </>
      )}

      <Modal
        size="lg"
        show={isOpenModal}
        fullscreen={"lg-down"}
        dialogClassName="custom-modal"
        // onHide={() => setIsOpenModal(false)}
      >
        <Modal.Header>
          <Modal.Title>CSV File Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <BootstrapTable
              id={styles.stock_details}
              tableHeaderClass={styles.headerClass}
              tableBodyClass={styles.bodyClass}
              bordered={false}
              data={state}
              version="4"
              pagination
              options={options}
              search
              scrollTop={"Bottom"}
              cellEdit={{
                mode: "dbclick",
                blurToSave: true,
                afterSaveCell: handleUpdatedCell,
              }}
              tableContainerClass={`table-responsive table-striped table-hover`}
            >
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                dataField="srNo"
                isKey
              >
                <span className="fs-sm">Sr No.</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                dataField="stone_id"
                columnTitle={(e, value) =>
                  value.stone_id_error !== "" ? value.stone_id_error : false
                }
                tdStyle={(e, value) => {
                  console.log("value", value);
                  return {
                    border: value.stone_id_error !== "" ? "1px solid red" : "",
                  };
                }}
              >
                <span className="fs-sm">Stone ID</span>
              </TableHeaderColumn>
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border: value.cert_no_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="cert_no"
                  dataSort
                  // dataFormat={certificate.bind(this)}
                >
                  <span className="fs-sm">Cerificate No.</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.cert_type_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="cert_type"
                >
                  <span className="fs-sm">Certifiacate Type</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.cert_url_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="cert_url"
                >
                  <span className="fs-sm">Certifiacate Url</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border: value.country_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="country"
                >
                  <span className="fs-sm">Country</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 2 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border: value.city_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="city"
                >
                  <span className="fs-sm">City</span>
                </TableHeaderColumn>
              )}
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                dataField="carat"
                tdStyle={(e, value) => {
                  return {
                    border: value.carat_error !== "" ? "1px solid red" : "",
                  };
                }}
              >
                <span className="fs-sm">Carat</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.shape_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="shape_id"
              >
                <span className="fs-sm">Shape</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.color_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="color_id"
              >
                <span className="fs-sm">Color</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.colors_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="colors_id"
              >
                <span className="fs-sm">Colors</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border:
                      value.overtone_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="overtone_id"
              >
                <span className="fs-sm">Overtone</span>
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border:
                      value.intensity_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="intensity_id"
              >
                <span className="fs-sm">Intensity</span>
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border:
                      value.clarity_id_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="clarity_id"
              >
                <span className="fs-sm">Clarity</span>
              </TableHeaderColumn>
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.polish_id_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="polish_id"
                >
                  <span className="fs-sm">Polish</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.symmetry_id_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="symmetry_id"
                >
                  <span className="fs-sm">Symmentry</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.fluorescence_id_error !== ""
                          ? "1px solid red"
                          : "",
                    };
                  }}
                  dataField="fluorescence_id"
                >
                  <span className="fs-sm">Fluorescence</span>
                </TableHeaderColumn>
              )}

              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.rapo_rate_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="rapo_rate"
              >
                <span className="fs-sm">Rapo Rate</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.discount_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="discount"
              >
                <span className="fs-sm">Discount</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                dataField="rate"
                tdStyle={(e, value) => {
                  return {
                    border: value.rate_error !== "" ? "1px solid red" : "",
                  };
                }}
              >
                <span className="fs-sm">Rate</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={customCell.bind(this)}
                tdStyle={(e, value) => {
                  return {
                    border: value.amount_error !== "" ? "1px solid red" : "",
                  };
                }}
                dataField="amount"
              >
                <span className="fs-sm">Amount</span>
              </TableHeaderColumn>
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.table_per_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="table_per"
                >
                  <span className="fs-sm">Table PER</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.depth_per_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="depth_per"
                >
                  <span className="fs-sm">Depth PER</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border: value.length_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="length"
                >
                  <span className="fs-sm">Lenght</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  dataField="width"
                  tdStyle={(e, value) => {
                    return {
                      border: value.width_error !== "" ? "1px solid red" : "",
                    };
                  }}
                >
                  <span className="fs-sm">Width</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 1 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border: value.height_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="height"
                >
                  <span className="fs-sm">Height</span>
                </TableHeaderColumn>
              )}
              {parseInt(diamondType) === 2 && (
                <TableHeaderColumn
                  dataFormat={customCell.bind(this)}
                  tdStyle={(e, value) => {
                    return {
                      border:
                        value.growth_type_error !== "" ? "1px solid red" : "",
                    };
                  }}
                  dataField="growth_type"
                >
                  <span className="fs-sm">Growth Type</span>
                </TableHeaderColumn>
              )}
            </BootstrapTable>
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpenModal(false);
                setLoading(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                const _state = _.map(state, (e) => {
                  return _.mapValues(e, (q) => (q === "N/A" ? "" : q));
                });
                props.saveCsvFile({
                  details: _state,
                  id: props.uploadCsvFileReducer.data.uuid,
                  diamond_type: diamondType,
                });
                setLoading(true);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  getAllMastersListReducer: state.masterListReducer.getAllMastersList,
  uploadCsvFileReducer: state.masterListReducer.uploadCsvFile,
  saveFileReducer: state.masterListReducer.saveCsvFile,
});
const mapDispatchToProps = (dispatch) => ({
  getAllMastersList: () => dispatch(getAllMastersList()),
  saveCsvFile: (details) => dispatch(saveCsvFile(details)),
  uploadCsvFile: (details) => dispatch(uploadCsvFile(details)),
  resetCsvFile: () => dispatch({ type: RESET_UPLOAD_CSV }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCsv);
