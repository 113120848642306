import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../Components/Loader";
import {
  downloadUploadHistory,
  getUploadHistoryList,
} from "../Services/Actions/masterListAction";
import moment from "moment";

function UploadHistory(props) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.getUploadHistoryList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.getUploadHistoryListReducer.success) {
      const data = props.getUploadHistoryListReducer.data;
      const list = data.map((e) => {
        return {
          ...e,
          upload_type: e.upload_type === 0 ? "Manual" : "File",
          status: e.status === 0 ? "Error" : "Complete",
          date: moment(e.created_at).format("yyyy-MM-DD"),
          time: moment(e.created_at).format("hh:mm:ss A"),
        };
      });
      setState(list);
      setLoading(false);
    }
  }, [props.getUploadHistoryListReducer]);

  const MyCustomCell = (cellProps) => {
    return (
      <td>
        <div
          className=""
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => props.downloadUploadHistory(cellProps.dataItem.id)}
        >
          Download
        </div>
      </td>
    );
  };

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      headerCellRender={(props) => <th className="fs-semi bold">{props}</th>}
      data={state}
      dataItemKey={"id"}
    >
      <GridColumn field="file_name" title="File Name" width="130" />
      <GridColumn field="upload_type" title="Upload Type" width="130" />
      <GridColumn field="date" title="Upload Date" width="130" />
      <GridColumn field="time" title="Upload Time" width="130" />
      <GridColumn field="status" title="Status" width="130" />
      <GridColumn field="total" title="Total Diamonds" width="130" />
      <GridColumn field="valid" title="Valid Diamonds" width="130" />
      <GridColumn field="invalid" title="Invalid Diamonds" width="130" />
      <GridColumn
        field="download"
        title="Download"
        width="130"
        cell={MyCustomCell}
      />
    </Grid>
  );
  return (
    <div>
      <div className="page-top-line mt-5">
        <h2 className="page-title">
          Upload History - <span className="fw-semi-bold">List</span>
        </h2>
      </div>
      {loading ? <Loader /> : <div className="mt-4">{grid}</div>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getUploadHistoryListReducer: state.masterListReducer.getUploadHistoryList,
});
const mapDispatchToProps = (dispatch) => ({
  getUploadHistoryList: () => dispatch(getUploadHistoryList()),
  downloadUploadHistory: (id) => dispatch(downloadUploadHistory(id)),

  // resetList: () => dispatch({ type: CONFIRM_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadHistory);
