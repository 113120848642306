import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCountryList, getProfile } from "../Services/Actions/authAction";
import Loader from "../Components/Loader";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { GET_PROFILE_RESET } from "../Services/Constant";

function UserProfile(props) {
  const InitialState = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    other_phone: "",
    company: "",
    website: "",
    job_title: "",
    group_title: "",
    business_type: "",
    buying_group: "",
    about: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    country_id: "",
  };

  const InitialError = {
    username: false,
    first_name: false,
    last_name: false,
    email: false,
    mobile: false,
    other_phone: false,
    company: false,
    website: false,
    job_title: false,
    group_title: false,
    business_type: false,
    buying_group: false,
    about: false,
    address: false,
    state: false,
    city: false,
    pincode: false,
    country_id: false,
  };

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(InitialState);
  const [error, setError] = useState(InitialError);

  const [countryDetails, setCountryDetails] = useState(null);

  const [key, setKey] = useState("profile");

  useEffect(() => {
    props.getCountryList();
  }, []);

  useEffect(() => {
    if (props.getCountryListReducer.success) {
      const data = props.getCountryListReducer.data;
      setCountryDetails(data);
      props.getProfile();
    }
  }, [props.getCountryListReducer]);

  useEffect(() => {
    if (props.getProfileReducer.success) {
      const data = props.getProfileReducer.data;
      const _list = {
        ...data,
        business_type: JSON.parse(data.business_type),
        buying_group: JSON.parse(data.buying_group),
      };
      setDetails({
        ..._list,
        country_id: !!countryDetails && countryDetails?.country.find(
          (country) => country.id === _list.country_id
        ).name,
        business_type: _list.business_type.map(
          (type) =>
            !!countryDetails && countryDetails.BusinessType[parseInt(type)]
        ),
        buying_group: _list.buying_group.map(
          (type) =>
            !!countryDetails && countryDetails.ByuingGroup[parseInt(type)]
        ),
      });
      setLoading(false);
      props.resetProfileData();
    }
  }, [props.getProfileReducer]);

  const handleInput = () => {};

  const handleUpdate = () => {};
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          {!!details && (
            <>
              <div className="d-flex justify-content-between   mb-4 flex-wrap">
                <h2 className="heading mb-0">Change Profile</h2>
              </div>
              <div className="content-block mb-5">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="profile" title="Profile">
                    <div className="col-md-6 mt-5">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td>User Name</td>
                            <td>:</td>
                            <td>
                              {details?.username !== "" && !!details?.username
                                ? details?.username
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>First Name</td>
                            <td>:</td>
                            <td>
                              {details?.first_name !== "" &&
                              !!details?.first_name
                                ? details?.first_name
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>:</td>
                            <td>
                              {details?.last_name !== "" && !!details?.last_name
                                ? details?.last_name
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>:</td>
                            <td>
                              {details?.email !== "" && !!details?.email
                                ? details?.email
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Phone No.</td>
                            <td>:</td>
                            <td>
                              {details?.mobile !== "" && !!details?.mobile
                                ? details?.mobile
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Other Phone No.</td>
                            <td>:</td>
                            <td>
                              {details?.other_phone !== "" &&
                              !!details?.other_phone
                                ? details?.other_phone
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Company</td>
                            <td>:</td>
                            <td>
                              {details?.company !== "" && !!details?.company
                                ? details?.company
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Website</td>
                            <td>:</td>
                            <td>
                              {details?.website !== "" && !!details?.website ? (
                                <span
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(details?.website, "_blank");
                                  }}
                                >
                                  {details?.website}
                                </span>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Job Title</td>
                            <td>:</td>
                            <td>
                              {details?.job_title !== "" && !!details?.job_title
                                ? details?.job_title
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Group Title</td>
                            <td>:</td>
                            <td>
                              {details?.group_title !== "" &&
                              !!details?.group_title
                                ? details?.group_title
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Bussiness Type</td>
                            <td>:</td>
                            <td>
                              {details?.business_type !== "" &&
                              !!details?.business_type
                                ? details?.business_type.join(", ")
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Buying Group</td>
                            <td>:</td>
                            <td>
                              {details?.buying_group !== "" &&
                              !!details?.buying_group
                                ? details?.buying_group.join(", ")
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>About</td>
                            <td>:</td>
                            <td>
                              {details?.about !== "" && !!details?.about
                                ? details?.about
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>:</td>
                            <td>
                              {details?.address !== "" && !!details?.address
                                ? details?.address
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>:</td>
                            <td>
                              {details?.state !== "" && !!details?.state
                                ? details?.state
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>City</td>
                            <td>:</td>
                            <td>
                              {details?.city !== "" && !!details?.city
                                ? details?.city
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Pincode</td>
                            <td>:</td>
                            <td>
                              {details?.pincode !== "" && !!details?.pincode
                                ? details?.pincode
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>:</td>
                            <td>
                              {details?.country_id !== "" &&
                              !!details?.country_id
                                ? details?.country_id
                                : "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="editProfile" title="Edit Profile">
                    <div className="col-md-6 mt-5">
                      <Form onSubmit={handleUpdate}>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">Username</Form.Label>
                              <Form.Control
                                type="text"
                                name="username"
                                value={details?.username}
                                placeholder="Enter Username"
                                onChange={handleInput}
                              />
                              {error.username && (
                                <span className="form-error">
                                  {error.username}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">Email</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                value={details?.email}
                                placeholder="Enter Email"
                                onChange={handleInput}
                              />
                              {error.email && (
                                <span className="form-error">
                                  {error.email}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                value={details?.first_name}
                                placeholder="Enter First Name"
                                onChange={handleInput}
                              />
                              {error.first_name && (
                                <span className="form-error">
                                  {error.first_name}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={details?.last_name}
                                placeholder="Enter Last Name"
                                onChange={handleInput}
                              />
                              {error.last_name && (
                                <span className="form-error">
                                  {error.last_name}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Phone No.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="mobile"
                                value={details?.mobile}
                                placeholder="Enter mobile no."
                                onChange={handleInput}
                              />
                              {error.mobile && (
                                <span className="form-error">
                                  {error.mobile}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Other Phone No.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="other_phone"
                                value={details?.other_phone}
                                placeholder="Enter other mobile no."
                                onChange={handleInput}
                              />
                              {error.other_phone && (
                                <span className="form-error">
                                  {error.other_phone}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">Company</Form.Label>
                              <Form.Control
                                type="text"
                                name="company"
                                value={details?.company}
                                placeholder="Enter company"
                                onChange={handleInput}
                              />
                              {error.company && (
                                <span className="form-error">
                                  {error.company}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">Website</Form.Label>
                              <Form.Control
                                type="text"
                                name="website"
                                value={details?.website}
                                placeholder="Enter website URL"
                                onChange={handleInput}
                              />
                              {error.website && (
                                <span className="form-error">
                                  {error.website}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Job Title
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="job_title"
                                value={details?.job_title}
                                placeholder="Enter job title"
                                onChange={handleInput}
                              />
                              {error.job_title && (
                                <span className="form-error">
                                  {error.job_title}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Group Title
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="group_title"
                                value={details?.group_title}
                                placeholder="Enter group title"
                                onChange={handleInput}
                              />
                              {error.group_title && (
                                <span className="form-error">
                                  {error.group_title}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Bussiness Type
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="business_type"
                                value={details?.business_type}
                                placeholder="Enter business type"
                                onChange={handleInput}
                              />
                              {error.business_type && (
                                <span className="form-error">
                                  {error.business_type}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-0">
                                Buying Group
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="buying_group"
                                value={details?.buying_group}
                                placeholder="Enter buying group"
                                onChange={handleInput}
                              />
                              {error.buying_group && (
                                <span className="form-error">
                                  {error.buying_group}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Button type="submit" className="mt-4 text-white">
                          Submit
                        </Button>
                      </Form>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  getProfileReducer: state.authReducer.getProfile,
  getCountryListReducer: state.authReducer.getCountryList,
});
const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  getCountryList: () => dispatch(getCountryList()),
  resetProfileData: () => dispatch({ type: GET_PROFILE_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
