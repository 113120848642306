import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import { getDashboardDetails } from "../Services/Actions/dashboardAction";
import { logout } from "../Services/Actions/authAction";
import { LOGOUT_RESET } from "../Services/Constant";

function Header(props) {
  const navigate = useNavigate();

  const [isLogout, setIsLogout] = useState(false);
  const [details, setDetails] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    if (props.getProfileReducer.success) {
      const data = props.getProfileReducer.data;
      setDetails(data);
      setUserName(
        `${data.first_name.toUpperCase().charAt(0)}${data.last_name
          .toUpperCase()
          .charAt(0)}`
      );
    }
  }, [props.getProfileReducer]);

  const handleLogout = () => {
    props.logout();
  };

  useEffect(() => {
    if (props.logoutReducer.success) {
      navigate("/");
      props.resetLogout();
    }
  }, [props.logoutReducer]);
  return (
    <>
      <header className="user-Header">
        <div className="d-flex align-items-center">
          <img
            style={{ cursor: "pointer" }}
            src={"/images/headerLogo.png"}
            className="img-fluid logo"
            alt="Logo"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          {/* <form>
            <div className="globalSearch">
              <input
                type="text"
                name="stone_id"
                // value={state.stone_id}
                // onChange={inputChange}
                className="form-control"
                placeholder="search for Stone ID, Pair ID, Certificate"
                id="search"
              />

              <button
                className="btn btn-search"
                type="button"
                // onClick={handleSubmit}
              >
                <i className="ri-search-line"></i>
              </button>
            </div>
          </form> */}
        </div>
        <div className="d-flex align-items-center me-3 ">
          {/* {!!diamondType && (
            <div
              className=" px-3 py-2 rounded text-white"
              style={{ background: "#C2B362" }}
            >
              {parseInt(diamondType) === 1
                ? "Natural Diamonds"
                : parseInt(diamondType) === 2
                ? "Lab-Grown Diamonds"
                : parseInt(diamondType) === 3
                ? "Star Melee Diamonds"
                : ""}
            </div>
          )} */}
          {/* <Link to="/cart" className="btn">
            <i className="ri-shopping-cart-2-line h5 mb-0"></i>
          </Link> */}
          {/* <Dropdown
            className="profile-drp notification-drp"
            drop="down-right"
            align="end"
          >
            <Dropdown.Toggle id="profile">
              <i className="fa-regular fa-bell h5 mb-0"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <h5 className="mb-0">Notifications</h5>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item new">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">Now</span>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">12:46 PM</span>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">12:46 PM</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <Dropdown className="profile-drp">
            <Dropdown.Toggle id="profile">
              <span className="profile-icon">{!!details && userName}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => navigate("/profile")}>
                <i className="fa-solid fa-user me-2 text-primary"></i> Change
                Profile
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => navigate("/change-password")}
              >
                <i className="fa-solid fa-unlock me-2 text-primary"></i> Change
                Password
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3"><i className="fa-solid fa-table-columns me-2 text-primary"></i>Set Column result</Dropdown.Item> */}
              {/* <Dropdown.Item href="/">
                <i class="fa-solid fa-address-card me-2 text-primary"></i>
                Website Profile
              </Dropdown.Item> */}
              <Dropdown.Item href="#" onClick={() => setIsLogout(true)}>
                <i className="fa-solid fa-arrow-right-from-bracket me-2 text-primary"></i>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <Sidebar setIsLogout={setIsLogout} />

      {/* logout */}
      <Modal
        show={isLogout}
        onHide={() => setIsLogout(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="text-center">Are you sure you want to logout?</div>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={() => setIsLogout(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  dashboardDetailsReducer: state.dashboardReducer.dashboardDetails,
  getProfileReducer: state.authReducer.getProfile,
  logoutReducer: state.authReducer.logout,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboardDetails: () => dispatch(getDashboardDetails()),
  logout: () => dispatch(logout()),
  resetLogout: () => dispatch({ type: LOGOUT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
