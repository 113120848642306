import axios from "axios";
import { dashboard_Url } from "../api_sheet";
import { DASHBOARD_ERROR, DASHBOARD_SUCCESS } from "../Constant";

export const getDashboardDetails = () => {
 return (dispatch) => {
  const requestOptions = {
   method: "GET",
   url: dashboard_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: DASHBOARD_SUCCESS,
     data: resp.data.data.result
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: DASHBOARD_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = error.response.data.error;
     dispatch({
      type: DASHBOARD_ERROR,
      msg: validationError,
     });
    } else {
     dispatch({
      type: DASHBOARD_ERROR,
      msg: error.response.data.error,
     });
    }
   });
 };
};