export const RESET_STORE = "RESET_STORE"


//  login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";

//  logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_RESET = "LOGOUT_RESET";

//  forgot pass
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

export const UPDATE_FORGOT_PASSWORD_SUCCESS = "UPDATE_FORGOT_PASSWORD_SUCCESS";
export const UPDATE_FORGOT_PASSWORD_ERROR = "UPDATE_FORGOT_PASSWORD_ERROR";
export const UPDATE_FORGOT_PASSWORD_RESET = "UPDATE_FORGOT_PASSWORD_RESET";



//  dashboars
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const DASHBOARD_RESET = "DASHBOARD_RESET";

//  profile
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const GET_PROFILE_RESET = "GET_PROFILE_RESET";

//  country List
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR";
export const GET_COUNTRY_LIST_RESET = "GET_COUNTRY_LIST_RESET";

//  change password
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

//  invalid stone
export const INVALID_STONE_LIST_SUCCESS = "INVALID_STONE_LIST_SUCCESS";
export const INVALID_STONE_LIST_ERROR = "INVALID_STONE_LIST_ERROR";
export const INVALID_STONE_LIST_RESET = "INVALID_STONE_LIST_RESET";

//  HOLD stone
export const HOLD_LIST_SUCCESS = "HOLD_LIST_SUCCESS";
export const HOLD_LIST_ERROR = "HOLD_LIST_ERROR";
export const HOLD_LIST_RESET = "HOLD_LIST_RESET";

//  confirmed stone
export const CONFIRM_LIST_SUCCESS = "CONFIRM_LIST_SUCCESS";
export const CONFIRM_LIST_ERROR = "CONFIRM_LIST_ERROR";
export const CONFIRM_LIST_RESET = "CONFIRM_LIST_RESET";



// upload history
export const UPLOAD_HISTORY_SUCCESS = 'UPLOAD_HISTORY_SUCCESS'
export const UPLOAD_HISTORY_ERROR = 'UPLOAD_HISTORY_ERROR'
export const RESET_UPLOAD_HISTORY = 'RESET_UPLOAD_HISTORY'

export const UPLOAD_HISTORY_DOWNLOAD_SUCCESS = 'UPLOAD_HISTORY_DOWNLOAD_SUCCESS'
export const UPLOAD_HISTORY_DOWNLOAD_ERROR = 'UPLOAD_HISTORY_DOWNLOAD_ERROR'
export const RESET_UPLOAD_HISTORY_DOWNLOAD = 'RESET_UPLOAD_HISTORY_DOWNLOAD'


//  stock management
export const GET_STOCK_LIST_LOADING = 'GET_STOCK_LIST_LOADING'
export const GET_STOCK_LIST_SUCCESS = 'GET_STOCK_LIST_SUCCESS'
export const GET_STOCK_LIST_ERROR = 'GET_STOCK_LIST_ERROR'
export const RESET_GET_STOCK_LIST = 'RESET_GET_STOCK_LIST'

export const ADD_STOCK_LIST_LOADING = 'ADD_STOCK_LIST_LOADING'
export const ADD_STOCK_LIST_SUCCESS = 'ADD_STOCK_LIST_SUCCESS'
export const ADD_STOCK_LIST_ERROR = 'ADD_STOCK_LIST_ERROR'
export const RESET_ADD_STOCK_LIST = 'RESET_ADD_STOCK_LIST'

export const UPDATE_STOCK_LIST_LOADING = 'UPDATE_STOCK_LIST_LOADING'
export const UPDATE_STOCK_LIST_SUCCESS = 'UPDATE_STOCK_LIST_SUCCESS'
export const UPDATE_STOCK_LIST_ERROR = 'UPDATE_STOCK_LIST_ERROR'
export const RESET_UPDATE_STOCK_LIST = 'RESET_UPDATE_STOCK_LIST'

export const STOCK_LIST_STATUS_SUCCESS = 'STOCK_LIST_STATUS_SUCCESS'
export const STOCK_LIST_STATUS_ERROR = 'STOCK_LIST_STATUS_ERROR'
export const RESET_STOCK_LIST_STATUS = 'RESET_STOCK_LIST_STATUS'

// all master list
export const GET_ALL_MASTERS_LIST_LOADING = 'GET_ALL_MASTERS_LIST_LOADING'
export const GET_ALL_MASTERS_LIST_SUCCESS = 'GET_ALL_MASTERS_LIST_SUCCESS'
export const GET_ALL_MASTERS_LIST_ERROR = 'GET_ALL_MASTERS_LIST_ERROR'
export const RESET_GET_ALL_MASTERS_LIST = 'RESET_GET_ALL_MASTERS_LIST'

// Upload csv file and save
export const UPLOAD_CSV_LOADING = 'UPLOAD_CSV_LOADING'
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS'
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR'
export const RESET_UPLOAD_CSV = 'RESET_UPLOAD_CSV'

export const SAVE_CSV_LOADING = 'SAVE_CSV_LOADING'
export const SAVE_CSV_SUCCESS = 'SAVE_CSV_SUCCESS'
export const SAVE_CSV_ERROR = 'SAVE_CSV_ERROR'
export const RESET_SAVE_CSV = 'RESET_SAVE_CSV'


// diamond reset
export const DIAMOND_RESET_SUCCESS = 'DIAMOND_RESET_SUCCESS'
export const DIAMOND_RESET_ERROR = 'DIAMOND_RESET_ERROR'
export const RESET_DIAMOND_RESET = 'RESET_DIAMOND_RESET'
