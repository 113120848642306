import axios from "axios";
import { changePassword_Url, countryList_Url, forgotPassword_URL, login_Url, logout_Url, profile_Url, resetPassword_URL, verifyOtp_URL } from "../api_sheet";
import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS, GET_COUNTRY_LIST_ERROR, GET_COUNTRY_LIST_SUCCESS, GET_PROFILE_ERROR, GET_PROFILE_SUCCESS, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_ERROR, LOGOUT_SUCCESS, UPDATE_FORGOT_PASSWORD_ERROR, UPDATE_FORGOT_PASSWORD_SUCCESS, VERIFY_OTP_ERROR, VERIFY_OTP_SUCCESS } from "../Constant";
import { toast } from "react-toastify";

export const login = (user) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: login_Url,
      data: user.details,
    };
    axios(requestOptions)
      .then((resp) => {
        localStorage.setItem("token", resp?.data.data.token);
        if (!!user.remeber) {
          document.cookie = `details=${JSON.stringify(
            user.details
          )}; expires=Thu, 18 Dec 9999 12:00:00 UTC`;
        } else {
          document.cookie = `details=${JSON.stringify(
            user.details
          )}; expires=Thu, 18 Dec 2001 12:00:00 UTC`;
        }
        console.log(resp, "loginAction");
        dispatch({
          type: LOGIN_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGIN_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = error.response.data.error;
          dispatch({
            type: LOGIN_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            msg: error.response.data.error,
          });
        }
      });
  };
};

export const logout = (user) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: logout_Url,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios(requestOptions)
      .then((resp) => {
        toast.success("Logout Successfully");
        localStorage.removeItem("token");
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGOUT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOGOUT_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const getProfile = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: profile_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PROFILE_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PROFILE_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = error.response.data.error;
          dispatch({
            type: GET_PROFILE_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: GET_PROFILE_ERROR,
            msg: error.response.data.error,
          });
        }
      });
  };
};


export const getCountryList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: countryList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_COUNTRY_LIST_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_COUNTRY_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = error.response.data.error;
          dispatch({
            type: GET_COUNTRY_LIST_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: GET_COUNTRY_LIST_ERROR,
            msg: error.response.data.error,
          });
        }
      });
  };
};


export const changePassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: changePassword_Url,
      data: details,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const forgotPassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: forgotPassword_URL,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const verifyOtp = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: verifyOtp_URL,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: VERIFY_OTP_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: VERIFY_OTP_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: VERIFY_OTP_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const updateForgotPassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: resetPassword_URL,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_FORGOT_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: UPDATE_FORGOT_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: UPDATE_FORGOT_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};
