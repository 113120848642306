import classNames from "classnames";
import React from "react";
import {useLocation } from "react-router-dom";

function InstructionPage() {
  const location = useLocation();
  console.log("location", location);
  return (
    <div>
      <div className="page-top-line mt-5 mb-5">
        <h2 className="page-title">
          Instruction - <span className="fw-semi-bold"> Parameters</span>
        </h2>
      </div>
      <div className="mb-5">
        <div className="row">
          <div className="col-3">
            <nav
              id="instruction"
              className="h-100 flex-column align-items-stretch pe-4 border-end "
            >
              <nav className="nav nav-pills flex-column">
                <a
                  className={classNames("nav-link", {
                    active: location.hash === "#Parameters",
                  })}
                  href="#Parameters"
                >
                  Parameters
                </a>
                <nav className="nav nav-pills flex-column">
                  <a
                    className={classNames("nav-link ms-3 my-1", {
                      active: location.hash === "#round-diamond",
                    })}
                    href="#round-diamond"
                  >
                    Round Diamonds
                  </a>
                  <a
                    className={classNames("nav-link ms-3 my-1", {
                      active: location.hash === "#fancy-shape",
                    })}
                    href="#fancy-shape"
                  >
                    Fancy Shape Diamond
                  </a>
                  <a
                    className={classNames("nav-link ms-3 my-1", {
                      active: location.hash === "#fancy-color",
                    })}
                    href="#fancy-color"
                  >
                    Fancy Color Diamond
                  </a>
                </nav>
              </nav>
            </nav>
          </div>

          <div
            className="col-9"
            style={{
              height: "78vh",
              overflowY: "auto",
              scrollBehavior: "smooth",
            }}
          >
            <div
              data-bs-spy="scroll"
              data-bs-target="#instruction"
              data-bs-smooth-scroll="true"
              // className="scrollspy-example-2"
              tabindex="0"
            >
              <div id="Parameters">
                <h4>Parameter</h4>
                <p className="text-justify mt-3">
                  In Diamond On Call Supplier Centre, each Diamond needs to
                  certified It undergoes a thorough, independent evaluation.
                  There are certain parameters for each Diamond type which
                  affects the Diamond’s price. The parameters are listed in each
                  of the page assigned to that particular type of diamond. Make
                  sure that the document uploaded via{" "}
                  <strong>Upload Diamonds </strong>page is in sync with the
                  parameter information mentioned here for each type of diamond.
                </p>
                <p className="mt-3">
                  Each type of diamond hasa table with the Field Names and
                  Acceptable Field Values.
                </p>
                <p className="mt-3">
                  To upload Diamond using Excel, FTP or API, follow the
                  parameters below.
                </p>
              </div>
              <div id="round-diamond" className="mt-5">
                <h4 className="font-weight-bold">Round Diamonds</h4>
                <p className="mt-4">
                  It is a round super-ideal cut diamond. It is most popular cut
                  in rings, followed by the princess cut and the cushion cut. It
                  has 57 angular facets divided amongst its crown, girdle and
                  pavillion.
                </p>
                <div>
                  <table className="table table-striped">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Field Names</th>
                        <th>Acceptable Field values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Stock #</td>
                        <td>Supplier Stock ID of Diamond</td>
                      </tr>
                      <tr>
                        <td>Availability</td>
                        <td>
                          Guaranteed Availability ={" "}
                          <b> G, AVAILABLE, A, AV, GA</b> <br />
                          MEMO, BUSY, HOLD = <b>B, M, H, NA</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Shape</td>
                        <td>
                          Round ={" "}
                          <b> B, BR, RB, RBC, R, RD, ROUND BRILLIANT, RND </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>
                          <b> 0.18 - 10+ ct</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          <b>
                            {" "}
                            D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX,
                            YZ{" "}
                          </b>{" "}
                          <br />
                          <p>
                            * To enter two colors, separate with a hyphen or no
                            space.Applied after color <b>"N"</b>.
                          </p>
                          <p>
                            <i>For Example, "O-P" or "OP"</i>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Clarity</td>
                        <td>
                          <b>
                            FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2, I3
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cut</td>
                        <td>
                          <p>
                            Ideal = <b>I, ID</b>
                            <i>( Will be in Excellent)</i>
                          </p>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Polish</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Symmetry</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Fluorescence Intensity</td>
                        <td>
                          <p>
                            None = <b>N, NON, NIL, NONE</b>
                          </p>
                          <p>
                            Very Good = <b>VS, VST</b>
                          </p>
                          <p>
                            Strong = <b>S, STG, ST</b>
                          </p>
                          <p>
                            Medium = <b>M, MED</b>
                          </p>
                          <p>
                            Faint = <b>F, FNT</b>
                          </p>
                          <p>
                            Slight = <b>SL, SLT, SLI</b>
                          </p>
                          <p>
                            Very Slight = <b>VSL, VSLI, VSLT</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Fluorescence Color{" "}
                          <div>
                            <i>(can be left blank)</i>
                          </div>
                        </td>
                        <td>
                          <p>
                            Blue = <b>B</b>
                          </p>
                          <p>
                            White = <b>W</b>
                          </p>
                          <p>
                            Yellow = <b>Y</b>
                          </p>
                          <p>
                            Orange = <b>O</b>
                          </p>
                          <p>
                            Red = <b>R</b>
                          </p>
                          <p>
                            Green = <b>G</b>
                          </p>
                          <p>
                            None, NIL, NON = <b>N</b>
                          </p>
                          <div className="mt-4">
                            * Default is "N" if left blank.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Measurements</td>
                        <td>
                          Symbols accepted are <b>- * x</b>
                          <i>
                            For Example, 6.40 x 6.43 * 3.97 or 6.40 - 6.43 x
                            3.97
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td>Lab</td>
                        <td>
                          <p>
                            GIA = <b>G, GIA</b>
                          </p>
                          <p>
                            HRD = <b>H, HRD</b>
                          </p>
                          <p>
                            IGI = <b>I, IGI</b>
                          </p>
                          <p>
                            AGS = <b>A, AGS</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Certificate #</td>
                        <td>
                          Enter the number on the certificate from the grading
                          lab.
                        </td>
                      </tr>
                      <tr>
                        <td>$/CT</td>
                        <td>
                          Price per carat (only in USD)
                          <div className="mt-3">
                            Enter numbers with 2 decimals with no sign of
                            currency. Do not Include "$" Sign.
                          </div>
                          <div className="mt-1">
                            Total price will be calculated by multiplying $/CT &
                            Carat Weight
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Intensity</td>
                        <td>
                          <b className="text-danger"> Must be empty </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Overtone </td>
                        <td>
                          <b className="text-danger"> Must be empty </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Depth %</td>
                        <td>
                          Example: 62.4 *
                          <div>
                            *Give percentage with only one number after the
                            decimal. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Table %</td>
                        <td>
                          Example: 55.5 *
                          <div>
                            *Give percentage with no digit after decimal. Do not
                            include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thin{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thick{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>

                          <div>
                            * Enter both Girdle Thin and Girdle Thick in this
                            different field.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle %{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Example: 3.5*
                          <div>
                            * Must be a positive number. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Polished = <b>P</b>
                          </p>
                          <p>
                            Faceted = <b>F</b>
                          </p>
                          <p>
                            Bruted = <b>B</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Culet Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <td>
                            <p>
                              Pointed = <b>P</b>
                            </p>
                            <p>
                              Abraded = <b>A</b>
                            </p>
                            <p>
                              Chipped = <b>C</b>
                            </p>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Height</td>
                        <td>
                          Example: 14.5*
                          <div>
                            *Give percentage with only one number after the
                            decimal. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Angle</td>
                        <td>
                          Example: 36.3*
                          <div>
                            * Give a value to one decimal place. Do not include
                            any degree symbol .
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Depth</td>
                        <td>
                          Example: 42.5*
                          <div>
                            * Give a value to one decimal place. Do not include
                            % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Angle</td>
                        <td>
                          Example: 40.6*
                          <div>
                            * Give a value to one decimal place. Do not include
                            any degree symbol .
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          <p>
                            India = <b>IN, Mumbai, Mum</b>
                          </p>
                          <p>
                            Israel = <b> IS, ISR</b>
                          </p>
                          <p>
                            USA = <b>US, USA, NY, LA</b>
                          </p>
                          <p>
                            Hong Kong = <b>HK</b>
                          </p>
                          <p>
                            Belgium = <b> BL, BG, ANT</b>
                          </p>
                          <p>
                            United Kingdom = <b>United Kingdom</b>
                          </p>
                          <p>
                            Other Countries ={" "}
                            <b>Mention the full name of country</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          DiamondImage{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Video{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Enter a URL of the Video.</td>
                      </tr>
                      <tr>
                        <td>
                          Heart Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Arrow Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Aset Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Key to symbols{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Bearding, Brown patch of color, Bruise, Cavity, Chip,
                          Cleavage, Cloud, Crystal, Crystal Surface, Etch
                          Channel, Extra Facet, Feather, Flux Remnant, Indented
                          Natural, Internal Graining, Internal Inscription,
                          Internal Laser Drilling, Knot, Laser Drill Hole,
                          Manufacturing Remnant, Minor Details of Polish,
                          Natural, Needle, No Inclusion, Pinpoint, Reﬂecting
                          Surface Graining, Surface Graining, Twinning Wisp
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Report Comments{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Comments or Remarks mentioned in the Certificate
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brown{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO BROWN = <b>N , NB, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT BROWN = <b>LB, BRN1, B1</b>
                          </p>
                          <p>
                            BROWN = <b>BRN2, BRN, BR, B2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Green{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO GREEN = <b>N, NG, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT GREEN = <b>LG, GRN1, G1</b>
                          </p>
                          <p>
                            GREEN = <b>GRN2, GRN, GN, G2</b>
                          </p>
                          <p>
                            MIX TINGE = <b>MT, Mixt, Mix</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Milky{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO MILKY = <b>EX, NO, N, NONE, NON</b>
                          </p>
                          <p>
                            LIGHT MILKY = <b>VG, L, M1, Light</b>
                          </p>
                          <p>
                            MILKY = <b>Yes, M2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Eye Clean{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            100% Eye Clean = <b>Yes, Y, 100</b>
                          </p>
                          <p>
                            Not 100% Eye Clean = <b>No, N</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brand{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Canada Mark = <b>CM, CME, C</b>
                          </p>
                          <p>
                            Forever Mark = <b>FM, F</b>
                          </p>
                          <p>
                            GIA DOR = <b>DOR, DORE, D</b>
                          </p>
                          <p>
                            Others = <b>O, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Origin
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Diamond origin place.</td>
                      </tr>
                      <tr>
                        <td>
                          Type II{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <b>A, B</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="fancy-shape" className="mt-5">
                <h4 className="font-weight-bold">Fancy Shape Diamond</h4>
                <p className="mt-4">
                  The diamond which are not round are referred as fancy shaped
                  diamond. It has various options like princess, emerald,
                  cushion, marquise, radiant, oval, pear, heart etc.
                </p>
                <div>
                  <table className="table table-striped">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Field Names</th>
                        <th>Acceptable Field values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Stock #</td>
                        <td>Supplier Stock ID of Diamond</td>
                      </tr>
                      <tr>
                        <td>Availability</td>
                        <td>
                          Guaranteed Availability ={" "}
                          <b> G, AVAILABLE, A, AV, GA</b> <br />
                          MEMO, BUSY, HOLD = <b>B, M, H, NA</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Shape</td>
                        <td>
                          <td>
                            <p>
                              Pear = <b>P, PS, PB, PMB, PE</b>
                            </p>
                            <p>
                              Emerald = <b>E, EM, EC</b>
                            </p>
                            <p>
                              Square Emerald, Asscher ={" "}
                              <b>SQE, SQEM, A, AC, AS, SQ EMERALD, SE, SEM</b>
                            </p>
                            <p>
                              Princess ={" "}
                              <b>
                                PRN, PR, PRIN, PC, SMB, SQUARE MODIFIED, SQUARE
                                MODIFIED BRILLIANT
                              </b>
                            </p>
                            <p>
                              Marquise = <b>M, MQ, MQB, MB</b>
                            </p>
                            <p>
                              Cushion ={" "}
                              <b>Cushion Brilliant, CB, C, CU, CUSH, CUBR</b>
                            </p>
                            <p>
                              Cushion Modified = <b>CM, CMB, CSMB</b>
                            </p>
                            <p>
                              Heart = <b>H, HS, HT, HB, HRT</b>
                            </p>
                            <p>
                              Oval = <b>O, OV, OMB, OB</b>
                            </p>
                            <p>
                              Long Radiant ={" "}
                              <b>RT, RAD, RA, RN, RADIANT, LR_BRILLIANT</b>
                            </p>
                            <p>
                              Square Radiant = <b>SR, Sq Radiant, SQR, SQRA</b>
                            </p>
                            <p>
                              Trilliant = <b>T, TR, TRIL,TRL</b>
                            </p>
                            <p>
                              Triangle = <b>TA, TRA, TRI</b>
                            </p>
                            <p>
                              Other ={" "}
                              <b>
                                Any other code will go under Other shapes , X
                              </b>
                            </p>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>
                          <b> 0.18 - 10+ ct</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          <b>
                            {" "}
                            D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX,
                            YZ{" "}
                          </b>{" "}
                          <br />
                          <p>
                            * To enter two colors, separate with a hyphen or no
                            space.Applied after color <b>"N"</b>.
                          </p>
                          <p>
                            <i>For Example, "O-P" or "OP"</i>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Clarity</td>
                        <td>
                          <b>
                            FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2, I3
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cut</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Polish</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Symmetry</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Fluorescence Intensity</td>
                        <td>
                          <p>
                            None = <b>N, NON, NIL, NONE</b>
                          </p>
                          <p>
                            Very Good = <b>VS, VST</b>
                          </p>
                          <p>
                            Strong = <b>S, STG, ST</b>
                          </p>
                          <p>
                            Medium = <b>M, MED</b>
                          </p>
                          <p>
                            Faint = <b>F, FNT</b>
                          </p>
                          <p>
                            Slight = <b>SL, SLT, SLI</b>
                          </p>
                          <p>
                            Very Slight = <b>VSL, VSLI, VSLT</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Fluorescence Color{" "}
                          <div>
                            <i>(can be left blank)</i>
                          </div>
                        </td>
                        <td>
                          <p>
                            Blue = <b>B</b>
                          </p>
                          <p>
                            White = <b>W</b>
                          </p>
                          <p>
                            Yellow = <b>Y</b>
                          </p>
                          <p>
                            Orange = <b>O</b>
                          </p>
                          <p>
                            Red = <b>R</b>
                          </p>
                          <p>
                            Green = <b>G</b>
                          </p>
                          <p>
                            None, NIL, NON = <b>N</b>
                          </p>
                          <div className="mt-4">
                            * Default is "N" if left blank.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Measurements</td>
                        <td>
                          Symbols accepted are <b>- * x</b>
                          <i>
                            For Example, 6.40 x 6.43 * 3.97 or 6.40 - 6.43 x
                            3.97
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td>Lab</td>
                        <td>
                          <p>
                            GIA = <b>G, GIA</b>
                          </p>
                          <p>
                            HRD = <b>H, HRD</b>
                          </p>
                          <p>
                            IGI = <b>I, IGI</b>
                          </p>
                          <p>
                            AGS = <b>A, AGS</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Certificate #</td>
                        <td>
                          Enter the number on the certificate from the grading
                          lab.
                        </td>
                      </tr>
                      <tr>
                        <td>$/CT</td>
                        <td>
                          Price per carat (only in USD)
                          <div className="mt-3">
                            Enter numbers with 2 decimals with no sign of
                            currency. Do not Include "$" Sign.
                          </div>
                          <div className="mt-1">
                            Total price will be calculated by multiplying $/CT &
                            Carat Weight
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Intensity</td>
                        <td>
                          <b className="text-danger"> Must be empty </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Overtone </td>
                        <td>
                          <b className="text-danger"> Must be empty </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Depth %</td>
                        <td>
                          Example: 62.4 *
                          <div>
                            *Give percentage with only one number after the
                            decimal. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Table %</td>
                        <td>
                          Example: 55.5 *
                          <div>
                            *Give percentage with no digit after decimal. Do not
                            include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thin{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thick{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>

                          <div>
                            * Enter both Girdle Thin and Girdle Thick in this
                            different field.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle %{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Example: 3.5*
                          <div>
                            * Must be a positive number. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Polished = <b>P</b>
                          </p>
                          <p>
                            Faceted = <b>F</b>
                          </p>
                          <p>
                            Bruted = <b>B</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Culet Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <td>
                            <p>
                              Pointed = <b>P</b>
                            </p>
                            <p>
                              Abraded = <b>A</b>
                            </p>
                            <p>
                              Chipped = <b>C</b>
                            </p>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Height</td>
                        <td>
                          Example: 14.5*
                          <div>
                            *Give percentage with only one number after the
                            decimal. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Angle</td>
                        <td>
                          Example: 36.3*
                          <div>
                            * Give a value to one decimal place. Do not include
                            any degree symbol .
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Depth</td>
                        <td>
                          Example: 42.5*
                          <div>
                            * Give a value to one decimal place. Do not include
                            % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Angle</td>
                        <td>
                          Example: 40.6*
                          <div>
                            * Give a value to one decimal place. Do not include
                            any degree symbol .
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          <p>
                            India = <b>IN, Mumbai, Mum</b>
                          </p>
                          <p>
                            Israel = <b> IS, ISR</b>
                          </p>
                          <p>
                            USA = <b>US, USA, NY, LA</b>
                          </p>
                          <p>
                            Hong Kong = <b>HK</b>
                          </p>
                          <p>
                            Belgium = <b> BL, BG, ANT</b>
                          </p>
                          <p>
                            United Kingdom = <b>United Kingdom</b>
                          </p>
                          <p>
                            Other Countries ={" "}
                            <b>Mention the full name of country</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          DiamondImage{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Video{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Enter a URL of the Video.</td>
                      </tr>
                      <tr>
                        <td>
                          Heart Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Arrow Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Aset Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Key to symbols{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Bearding, Brown patch of color, Bruise, Cavity, Chip,
                          Cleavage, Cloud, Crystal, Crystal Surface, Etch
                          Channel, Extra Facet, Feather, Flux Remnant, Indented
                          Natural, Internal Graining, Internal Inscription,
                          Internal Laser Drilling, Knot, Laser Drill Hole,
                          Manufacturing Remnant, Minor Details of Polish,
                          Natural, Needle, No Inclusion, Pinpoint, Reﬂecting
                          Surface Graining, Surface Graining, Twinning Wisp
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Report Comments{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Comments or Remarks mentioned in the Certificate
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brown{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO BROWN = <b>N , NB, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT BROWN = <b>LB, BRN1, B1</b>
                          </p>
                          <p>
                            BROWN = <b>BRN2, BRN, BR, B2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Green{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO GREEN = <b>N, NG, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT GREEN = <b>LG, GRN1, G1</b>
                          </p>
                          <p>
                            GREEN = <b>GRN2, GRN, GN, G2</b>
                          </p>
                          <p>
                            MIX TINGE = <b>MT, Mixt, Mix</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Milky{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO MILKY = <b>EX, NO, N, NONE, NON</b>
                          </p>
                          <p>
                            LIGHT MILKY = <b>VG, L, M1, Light</b>
                          </p>
                          <p>
                            MILKY = <b>Yes, M2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Eye Clean{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            100% Eye Clean = <b>Yes, Y, 100</b>
                          </p>
                          <p>
                            Not 100% Eye Clean = <b>No, N</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brand{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Canada Mark = <b>CM, CME, C</b>
                          </p>
                          <p>
                            Forever Mark = <b>FM, F</b>
                          </p>
                          <p>
                            GIA DOR = <b>DOR, DORE, D</b>
                          </p>
                          <p>
                            Others = <b>O, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Origin
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Diamond origin place.</td>
                      </tr>
                      <tr>
                        <td>
                          Type II{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <b>A, B</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="fancy-color" className="mt-5">
                <h4 className="font-weight-bold">Fancy Colored Diamond</h4>
                <p className="mt-4">
                  Fancy coloured diamonds are extremely rare which are the most
                  valuable variety of diamonds. Their most important feature is
                  their striking colour, which can range from pink to yellow and
                  even chameleon. It is a very unique piece of jewellery with
                  its rare color.
                </p>
                <div>
                  <table className="table table-striped">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Field Names</th>
                        <th>Acceptable Field values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Stock #</td>
                        <td>Supplier Stock ID of Diamond</td>
                      </tr>
                      <tr>
                        <td>Availability</td>
                        <td>
                          Guaranteed Availability ={" "}
                          <b> G, AVAILABLE, A, AV, GA</b> <br />
                          MEMO, BUSY, HOLD = <b>B, M, H, NA</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Shape</td>
                        <td>
                          <p>
                            Round ={" "}
                            <b>B, BR, RB, RBC, R, RD, ROUND BRILLIANT, RND</b>
                          </p>
                          <p>
                            Pear = <b>P, PS, PB, PMB, PE</b>
                          </p>
                          <p>
                            Emerald = <b>E, EM, EC</b>
                          </p>
                          <p>
                            Square Emerald, Asscher ={" "}
                            <b>SQE, SQEM, A, AC, AS, SQ EMERALD, SE, SEM</b>
                          </p>
                          <p>
                            Princess ={" "}
                            <b>
                              PRN, PR, PRIN, PC, SMB, SQUARE MODIFIED, SQUARE
                              MODIFIED BRILLIANT
                            </b>
                          </p>
                          <p>
                            Marquise = <b>M, MQ, MQB, MB</b>
                          </p>
                          <p>
                            Cushion ={" "}
                            <b>Cushion Brilliant, CB, C, CU, CUSH, CUBR</b>
                          </p>
                          <p>
                            Cushion Modified = <b>CM, CMB, CSMB</b>
                          </p>
                          <p>
                            Heart = <b>H, HS, HT, HB, HRT</b>
                          </p>
                          <p>
                            Oval = <b>O, OV, OMB, OB</b>
                          </p>
                          <p>
                            Long Radiant ={" "}
                            <b>RT, RAD, RA, RN, RADIANT, LR_BRILLIANT</b>
                          </p>
                          <p>
                            Square Radiant = <b>SR, Sq Radiant, SQR, SQRA</b>
                          </p>
                          <p>
                            Trilliant = <b>T, TR, TRIL,TRL</b>
                          </p>
                          <p>
                            Triangle = <b>TA, TRA, TRI</b>
                          </p>
                          <p>
                            Other ={" "}
                            <b>Any other code will go under Other shapes , X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>
                          <b> 0.18 - 10+ ct</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          <b>FANCY , *</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Clarity</td>
                        <td>
                          <b>
                            FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2, I3
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cut</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Polish</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Symmetry</td>
                        <td>
                          <p>
                            Excellent = <b>EX, EXC</b>
                          </p>
                          <p>
                            Very Good = <b>VG</b>
                          </p>
                          <p>
                            Good = <b>G, GD</b>
                          </p>
                          <p>
                            Fair = <b>F, FR</b>
                          </p>
                          <p>
                            Poor = <b>P, PR</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Fluorescence Intensity</td>
                        <td>
                          <p>
                            None = <b>N, NON, NIL, NONE</b>
                          </p>
                          <p>
                            Very Good = <b>VS, VST</b>
                          </p>
                          <p>
                            Strong = <b>S, STG, ST</b>
                          </p>
                          <p>
                            Medium = <b>M, MED</b>
                          </p>
                          <p>
                            Faint = <b>F, FNT</b>
                          </p>
                          <p>
                            Slight = <b>SL, SLT, SLI</b>
                          </p>
                          <p>
                            Very Slight = <b>VSL, VSLI, VSLT</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Fluorescence Color{" "}
                          <div>
                            <i>(can be left blank)</i>
                          </div>
                        </td>
                        <td>
                          <p>
                            Blue = <b>B</b>
                          </p>
                          <p>
                            White = <b>W</b>
                          </p>
                          <p>
                            Yellow = <b>Y</b>
                          </p>
                          <p>
                            Orange = <b>O</b>
                          </p>
                          <p>
                            Red = <b>R</b>
                          </p>
                          <p>
                            Green = <b>G</b>
                          </p>
                          <p>
                            None, NIL, NON = <b>N</b>
                          </p>
                          <div className="mt-4">
                            * Default is "N" if left blank.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Measurements</td>
                        <td>
                          Symbols accepted are <b>- * x</b>
                          <i>
                            For Example, 6.40 x 6.43 * 3.97 or 6.40 - 6.43 x
                            3.97
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td>Lab</td>
                        <td>
                          <p>
                            GIA = <b>G, GIA</b>
                          </p>
                          <p>
                            HRD = <b>H, HRD</b>
                          </p>
                          <p>
                            IGI = <b>I, IGI</b>
                          </p>
                          <p>
                            AGS = <b>A, AGS</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Certificate #</td>
                        <td>
                          Enter the number on the certificate from the grading
                          lab.
                        </td>
                      </tr>
                      <tr>
                        <td>$/CT</td>
                        <td>
                          Price per carat (only in USD)
                          <div className="mt-3">
                            Enter numbers with 2 decimals with no sign of
                            currency. Do not Include "$" Sign.
                          </div>
                          <div className="mt-1">
                            Total price will be calculated by multiplying $/CT &
                            Carat Weight
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color</td>
                        <td>
                          <p>
                            Black = <b>BK</b>
                          </p>
                          <p>
                            Blue = <b>B</b>
                          </p>
                          <p>
                            Brown = <b>BN</b>
                          </p>
                          <p>
                            Chameleon = <b>CH</b>
                          </p>
                          <p>
                            Cognac = <b>CG</b>
                          </p>
                          <p>
                            Gray, Grey = <b>GY</b>
                          </p>
                          <p>
                            Green = <b>KG</b>
                          </p>
                          <p>
                            Orange = <b>O</b>
                          </p>
                          <p>
                            Pink = <b>P</b>
                          </p>
                          <p>
                            Purple = <b>PL</b>
                          </p>
                          <p>
                            Red = <b>R</b>
                          </p>
                          <p>
                            Violet = <b>V</b>
                          </p>
                          <p>
                            Yellow = <b>Y</b>
                          </p>
                          <p>
                            White = <b>W</b>
                          </p>
                          <p>
                            Other,OT = <b>X</b>
                          </p>
                          <div className="mt-4">
                            * To enter two colors, separate with a hyphen or a
                            space.
                          </div>
                          <div className="mt-4">
                            For Example: Blue-Green, Blue Green, BG, B G
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Intensity</td>
                        <td>
                          <p>
                            Faint = <b>F</b>
                          </p>
                          <p>
                            Very Light = <b>VL</b>
                          </p>
                          <p>
                            Light = <b>L</b>
                          </p>
                          <p>
                            Fancy Light = <b>FL, FCL</b>
                          </p>
                          <p>
                            Fancy Dark = <b>FCD, DARK</b>
                          </p>
                          <p>
                            Fancy Intense = <b>I,FI, INTENSE</b>
                          </p>
                          <p>
                            Fancy Vivid = <b>V, FV, VIVID</b>
                          </p>
                          <p>
                            Fancy Deep = <b>D, FD, DEEP</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Fancy Color Overtone </td>
                        <td>
                          <p>
                            List any overtones in the color as mentioned below
                            in full
                          </p>
                          <p>
                            No Overtone = <b>NONE *</b>
                          </p>
                          <p>
                            <b>YELLOW</b>
                          </p>
                          <p>
                            <b>YELLOWISH</b>
                          </p>
                          <p>
                            <b>PINK</b>
                          </p>
                          <p>
                            <b>PINKISH</b>
                          </p>
                          <p>
                            <b>BLUE</b>
                          </p>
                          <p>
                            <b>BLUISH</b>
                          </p>
                          <p>
                            <b>RED</b>
                          </p>
                          <p>
                            <b>REDDISH</b>
                          </p>
                          <p>
                            <b>GREEN</b>
                          </p>
                          <p>
                            <b>GREENISH</b>
                          </p>
                          <p>
                            <b>PURPLE</b>
                          </p>
                          <p>
                            <b>PURPLISH</b>
                          </p>
                          <p>
                            <b>ORANGE</b>
                          </p>
                          <p>
                            <b>ORANGEY</b>
                          </p>
                          <p>
                            <b>VIOLET</b>
                          </p>
                          <p>
                            <b>GRAY</b>
                          </p>
                          <p>
                            <b>GRAYISH</b>
                          </p>
                          <p>
                            <b>BLACK</b>
                          </p>
                          <p>
                            <b>BROWN</b>
                          </p>
                          <p>
                            <b>BROWNISH</b>
                          </p>
                          <p>
                            <b>CHAMPANGE</b>
                          </p>
                          <p>
                            <b>COGNAC</b>
                          </p>
                          <p>
                            <b>CHAMELEON</b>
                          </p>
                          <p>
                            <b>VIOLETISH</b>
                          </p>
                          <p>
                            <b>WHITE</b>
                          </p>
                          <p>
                            Any other Ovetone = <b>OTHERS</b>
                          </p>
                          <div>
                            * To enter two ovetones, Separate with a hyphen or a
                            space. For Example: Brownish-Yellowish or Brownish
                            Yellowish
                          </div>
                          <div>
                            * if left blank will be considered as No Overtone.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Depth %</td>
                        <td>
                          Example: 62.4 *
                          <div>
                            *Give percentage with only one number after the
                            decimal. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Table %</td>
                        <td>
                          Example: 55.5 *
                          <div>
                            *Give percentage with no digit after decimal. Do not
                            include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thin{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Thick{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Extremely Thick ={" "}
                            <b>
                              XTK, EXTHICK, EXTHK, XTHK, XTHICK, XTHIK, ETK, EK,
                              XK
                            </b>
                          </p>
                          <p>
                            Very Thick = <b>VTK, VTHCK, VTHK, VTHICK</b>
                          </p>
                          <p>
                            Thick = <b>TK, THK, THIK, THIC</b>
                          </p>
                          <p>
                            Slightly Thick = <b>STK, SLTK, SLTHK</b>
                          </p>
                          <p>
                            Medium = <b>M, MED, MD</b>
                          </p>
                          <p>
                            Thin = <b>TN, THN</b>
                          </p>
                          <p>
                            Slightly Thin = <b>STN, SLTN, SLTHN</b>
                          </p>
                          <p>
                            Very Thin = <b> VTN, VTHN, VTHIN, VN</b>
                          </p>
                          <p>
                            Extremely Thin = <b>XTN, XTHN, EXTN, ETN, EN, X</b>
                          </p>

                          <div>
                            * Enter both Girdle Thin and Girdle Thick in this
                            different field.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle %{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Example: 3.5*
                          <div>
                            * Must be a positive number. Do not include % sign.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Girdle Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Polished = <b>P</b>
                          </p>
                          <p>
                            Faceted = <b>F</b>
                          </p>
                          <p>
                            Bruted = <b>B</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Culet Condition{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <td>
                            <p>
                              Pointed = <b>P</b>
                            </p>
                            <p>
                              Abraded = <b>A</b>
                            </p>
                            <p>
                              Chipped = <b>C</b>
                            </p>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Height</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Crown Angle</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Depth</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Pavilion Angle</td>
                        <td>
                          <b className="text-danger"> Must be empty</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          <p>
                            India = <b>IN, Mumbai, Mum</b>
                          </p>
                          <p>
                            Israel = <b> IS, ISR</b>
                          </p>
                          <p>
                            USA = <b>US, USA, NY, LA</b>
                          </p>
                          <p>
                            Hong Kong = <b>HK</b>
                          </p>
                          <p>
                            Belgium = <b> BL, BG, ANT</b>
                          </p>
                          <p>
                            United Kingdom = <b>United Kingdom</b>
                          </p>
                          <p>
                            Other Countries ={" "}
                            <b>Mention the full name of country</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          DiamondImage{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Video{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Enter a URL of the Video.</td>
                      </tr>
                      <tr>
                        <td>
                          Heart Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Arrow Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Aset Image{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Enter the URL of the image - only .JPG and . PNG links
                          allowed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Key to symbols{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Bearding, Brown patch of color, Bruise, Cavity, Chip,
                          Cleavage, Cloud, Crystal, Crystal Surface, Etch
                          Channel, Extra Facet, Feather, Flux Remnant, Indented
                          Natural, Internal Graining, Internal Inscription,
                          Internal Laser Drilling, Knot, Laser Drill Hole,
                          Manufacturing Remnant, Minor Details of Polish,
                          Natural, Needle, No Inclusion, Pinpoint, Reﬂecting
                          Surface Graining, Surface Graining, Twinning Wisp
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Report Comments{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          Comments or Remarks mentioned in the Certificate
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brown{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO BROWN = <b>N , NB, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT BROWN = <b>LB, BRN1, B1</b>
                          </p>
                          <p>
                            BROWN = <b>BRN2, BRN, BR, B2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Green{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO GREEN = <b>N, NG, NO, NON, None</b>
                          </p>
                          <p>
                            LIGHT GREEN = <b>LG, GRN1, G1</b>
                          </p>
                          <p>
                            GREEN = <b>GRN2, GRN, GN, G2</b>
                          </p>
                          <p>
                            MIX TINGE = <b>MT, Mixt, Mix</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Milky{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            NO MILKY = <b>EX, NO, N, NONE, NON</b>
                          </p>
                          <p>
                            LIGHT MILKY = <b>VG, L, M1, Light</b>
                          </p>
                          <p>
                            MILKY = <b>Yes, M2</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Eye Clean{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            100% Eye Clean = <b>Yes, Y, 100</b>
                          </p>
                          <p>
                            Not 100% Eye Clean = <b>No, N</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Brand{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <p>
                            Canada Mark = <b>CM, CME, C</b>
                          </p>
                          <p>
                            Forever Mark = <b>FM, F</b>
                          </p>
                          <p>
                            GIA DOR = <b>DOR, DORE, D</b>
                          </p>
                          <p>
                            Others = <b>O, X</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Origin
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>Diamond origin place.</td>
                      </tr>
                      <tr>
                        <td>
                          Type II{" "}
                          <p>
                            <i>(can be left blank)</i>
                          </p>
                        </td>
                        <td>
                          <b>A, B</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructionPage;
