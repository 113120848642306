import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addStockList,
  getAllMastersList,
  getStockList,
  updateStockList,
} from "../../Services/Actions/masterListAction";
import {
  RESET_ADD_STOCK_LIST,
  RESET_UPDATE_STOCK_LIST,
} from "../../Services/Constant";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";

function AddSingleDiamond(props) {
  const [details, setDetails] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const validUrlExpression = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~# &=]*)/g
  );

  const initaialState = {
    stone_id: "",
    cert_no: "",
    cert_type: "",
    cert_url: "",
    image: "",
    video: "",
    diamond_type: "",
    // size_id: "",
    carat: "",
    shape_id: "",
    color_id: "",
    colors_id: "",
    overtone_id: "",
    intensity_id: "",
    clarity_id: "",
    cut_id: "",
    polish_id: "",
    symmetry_id: "",
    fluorescence_id: "",
    fluorescence_color_id: "",
    rapo_rate: "",
    discount: "",
    rate: "",
    amount: "",
    // table: "",
    table_per: "",
    // depth: "",
    depth_per: "",
    length: "",
    width: "",
    height: "",
    ratio: "",
    bgm_id: "",
    city: "",
    country: "",
    pair: "",
    h_a: "",
    growth_type: "",
    eye_clean: false,
    milky: "",
    shade: "",
    crown_angle: "",
    crown_height: "",
    pavilion_angle: "",
    pavilion_height: "",
    white_table: "",
    white_side: "",
    table_black: "",
    side_black: "",
    table_open: "",
    pavilion_open: "",
    crown_open: "",
    girdle: "",
    girdle_desc: "",
    culet: "",
    key_to_symbols: "",
    availability: "",
    treatment: "",
    inscription: "",
    comment: "",
  };

  const initialError = {
    stone_id: false,
    cert_no: false,
    cert_type: false,
    cert_url: false,
    image: false,
    video: false,
    diamond_type: false,
    // size_id: false,
    carat: false,
    shape_id: false,
    color_id: false,
    colors_id: false,
    overtone_id: false,
    intensity_id: false,
    clarity_id: false,
    cut_id: false,
    polish_id: false,
    symmetry_id: false,
    fluorescence_id: false,
    fluorescence_color_id: false,
    rapo_rate: false,
    discount: false,
    rate: false,
    amount: false,
    length: false,
    width: false,
    height: false,
    ratio: false,
    bgm_id: false,
    city: false,
    country: false,
    table_per: false,
    depth_per: false,
    pair: false,
    h_a: false,
    growth_type: false,
    milky: false,
    shade: false,
    crown_angle: false,
    crown_height: false,
    pavilion_angle: false,
    pavilion_height: false,
    white_table: false,
    white_side: false,
    table_black: false,
    side_black: false,
    table_open: false,
    pavilion_open: false,
    crown_open: false,
    girdle: false,
    girdle_desc: false,
    culet: false,
    key_to_symbols: false,
    availability: false,
    treatment: false,
    inscription: false,
    comment: false,
  };

  const [state, setState] = useState(initaialState);
  const availabilityList = [
    "Available",
    "Business Process",
    "NewNew",
    "Upcoming",
  ];
  const [error, setError] = useState(initialError);
  console.log("?", state);
  useEffect(() => {
    if (!props.getAllMastersListReducer.success) {
      props.getAllMastersList();
    }
  }, []);

  useEffect(() => {
    if (props.getAllMastersListReducer.success) {
      const data = props.getAllMastersListReducer.data;
      setDetails(data);
    }
  }, [props.getAllMastersListReducer]);

  useEffect(() => {
    if (props.addStockListReducer.success) {
      props.getStockList();
      props.resetAddStockList();
      toast.success("Stock added successfully");
      navigate("/upload-diamonds");
    } else if (props.addStockListReducer.error) {
      const errorMsg = props.addStockListReducer.msg;
      Object.keys(errorMsg).forEach((e) => {
        setError((prev) => ({
          ...prev,
          [e]: `* ${errorMsg[e][0]}`,
        }));
      });
    }
  }, [props.addStockListReducer]);

  useEffect(() => {
    if (props.updateStockListReducer.success) {
      props.getStockList();
      props.resetUpdateStockList();
      toast.success("Stock updated successfully");
      navigate("/upload-diamonds");
    } else if (props.updateStockListReducer.error) {
      const errorMsg = props.updateStockListReducer.msg;
      Object.keys(errorMsg).forEach((e) => {
        setError((prev) => ({
          ...prev,
          [e]: `* ${errorMsg[e][0]}`,
        }));
      });
    }
  }, [props.updateStockListReducer]);

  useEffect(() => {
    if (!props.StockListReducer.success) {
      props.getStockList();
    }
  }, []);

  useEffect(() => {
    if (!!params.id && props.StockListReducer.success) {
      const data = props.StockListReducer.data;
      const _details = data.find((e) => {
        return e.id === params.id;
      });
      console.log("_details", _details);
      setState({
        ..._details,
        eye_clean:
          _details.eye_clean === "1" || _details.eye_clean === "Yes"
            ? true
            : false,
      });
    }
  }, [props.StockListReducer, params]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (
      name === "carat" ||
      name === "ratio" ||
      name === "height" ||
      name === "width" ||
      name === "length" ||
      name === "milky" ||
      name === "shade" ||
      name === "crown_angle" ||
      name === "crown_height" ||
      name === "pavilion_angle" ||
      name === "pavilion_height"
    ) {
      if (RegExp(/^([0-9]{0,5}([.][0-9]{0,2})?)$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (name === "rapo_rate" || name === "rate" || name === "amount") {
      if (RegExp(/^([0-9]{0,10}([.][0-9]{0,2})?)$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (
      name === "discount" ||
      name === "depth_per" ||
      name === "table_per" ||
      name === "girdle"
    ) {
      if (RegExp(/^([0-9]{0,2}([.][0-9]{0,2})?|100)$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (name === "stone_id" || name === "pair") {
      if (RegExp(/^([a-zA-Z0-9]{0,12})$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (name === "cert_no") {
      if (RegExp(/^([0-9]{0,12})$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (name === "city") {
      if (RegExp(/^[a-zA-Z]+$/i).test(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "stone_id":
        if (value === "") {
          error["stone_id"] = "* Please enter stone Id";
        } else {
          error["stone_id"] = false;
        }
        break;
      case "cert_no":
        if (value === "") {
          error["cert_no"] = "* Please enter certificate no.";
        } else {
          error["cert_no"] = false;
        }
        break;
      case "cert_type":
        if (value === "") {
          error["cert_type"] = "* Please enter cerificate type";
        } else {
          error["cert_type"] = false;
        }
        break;
      case "cert_url":
        if (value === "") {
          error["cert_url"] = "* Please enter certificate URL";
        } else if (!validUrlExpression.test(value)) {
          error["cert_url"] = "* Please enter valid URL";
        } else {
          error["cert_url"] = false;
        }
        break;
      case "image":
        if (!validUrlExpression.test(value)) {
          error["image"] = "* Please enter valid URL";
        } else {
          error["image"] = false;
        }
        break;
      case "video":
        if (!validUrlExpression.test(value)) {
          error["video"] = "* Please enter valid URL";
        } else {
          error["video"] = false;
        }
        break;
      case "diamond_type":
        if (value === "") {
          error["diamond_type"] = "* Please Diamond Type";
        } else {
          error["diamond_type"] = false;
        }
        break;
      // case "availability":
      //   if (value === "") {
      //     error["availability"] = "* Please select Availability";
      //   } else {
      //     error["availability"] = false;
      //   }
      //   break;
      case "carat":
        if (value === "") {
          error["carat"] = "* Please enter carat";
        } else {
          error["carat"] = false;
        }
        break;
      case "shape_id":
        if (value === "") {
          error["shape_id"] = "* Please select shape";
        } else {
          error["shape_id"] = false;
        }
        break;
      case "color_id":
        if (value === "") {
          error["color_id"] = "* Please select color";
        } else {
          error["color_id"] = false;
        }
        break;
      case "colors_id":
        if (value === "") {
          error["colors_id"] = "* Please select fancy color";
        } else {
          error["colors_id"] = false;
        }
        break;
      case "overtone_id":
        if (value === "") {
          error["overtone_id"] = "* Please select overtone";
        } else {
          error["overtone_id"] = false;
        }
        break;
      case "intensity_id":
        if (value === "") {
          error["intensity_id"] = "* Please select intensity";
        } else {
          error["intensity_id"] = false;
        }
        break;
      case "clarity_id":
        if (value === "") {
          error["clarity_id"] = "* Please select clarity";
        } else {
          error["clarity_id"] = false;
        }
        break;
      // case "cut_id":
      //   if (value === "") {
      //     error["cut_id"] = "* Please select cut";
      //   } else {
      //     error["cut_id"] = false;
      //   }
      //   break;
      case "polish_id":
        if (value === "") {
          error["polish_id"] = "* Please select polish";
        } else {
          error["polish_id"] = false;
        }
        break;
      case "symmetry_id":
        if (value === "") {
          error["symmetry_id"] = "* Please select symmentry";
        } else {
          error["symmetry_id"] = false;
        }
        break;
      case "fluorescence_id":
        if (value === "") {
          error["fluorescence_id"] = "* Please select fluorescence";
        } else {
          error["fluorescence_id"] = false;
        }
        break;
      // case "fluorescence_color_id":
      //   if (value === "") {
      //     error["fluorescence_color_id"] = "* Please select fluorescence color";
      //   } else {
      //     error["fluorescence_color_id"] = false;
      //   }
      //   break;
      case "rapo_rate":
        if (value === "") {
          error["rapo_rate"] = "* Please enter rapo rate";
        } else {
          error["rapo_rate"] = false;
        }
        break;
      case "discount":
        if (value === "") {
          error["discount"] = "* Please enter discount";
        } else {
          error["discount"] = false;
        }
        break;
      case "rate":
        if (value === "") {
          error["rate"] = "* Please enter rate";
        } else {
          error["rate"] = false;
        }
        break;
      case "amount":
        if (value === "") {
          error["amount"] = "* Please enter amount";
        } else {
          error["amount"] = false;
        }
        break;
      case "table_per":
        if (value === "" || parseFloat(value) <= 0) {
          error["table_per"] = "* Please enter table %";
        } else {
          error["table_per"] = false;
        }
        break;
      case "depth_per":
        if (value === "" || parseFloat(value) <= 0) {
          error["depth_per"] = "* Please enter depth %";
        } else {
          error["depth_per"] = false;
        }
        break;
      case "length":
        if (value === "") {
          error["length"] = "* Please enter lenght";
        } else {
          error["length"] = false;
        }
        break;
      case "width":
        if (value === "") {
          error["width"] = "* Please enter width";
        } else {
          error["width"] = false;
        }
        break;
      case "height":
        if (value === "") {
          error["height"] = "* Please enter height";
        } else {
          error["height"] = false;
        }
        break;
      case "country":
        if (value === "") {
          error["country"] = "* Please enter country";
        } else {
          error["country"] = false;
        }
        break;
      case "city":
        if (value === "") {
          error["city"] = "* Please enter city";
        } else {
          error["city"] = false;
        }
        break;
      case "growth_type":
        if (value === "") {
          error["growth_type"] = "* Please select Growth type";
        } else {
          error["growth_type"] = false;
        }
        break;

      // case "ratio":
      //   if (value === "") {
      //     error["ratio"] = "* Please enter ratio";
      //   } else {
      //     error["ratio"] = false;
      //   }
      //   break;
      // case "bgm_id":
      //   if (value === "") {
      //     error["bgm_id"] = "* Please select BGM id";
      //   } else {
      //     error["bgm_id"] = false;
      //   }
      //   break;

      // case "pair":
      //   if (value === "") {
      //     error["pair"] = "* Please enter pair";
      //   } else {
      //     error["pair"] = false;
      //   }
      //   break;
      // case "h_a":
      //   if (value === "") {
      //     error["h_a"] = "* Please enter h_a";
      //   } else {
      //     error["h_a"] = false;
      //   }
      //   break;
      // case "milky":
      //   if (value === "") {
      //     error["milky"] = "* Please enter milky value";
      //   } else {
      //     error["milky"] = false;
      //   }
      //   break;
      // case "shade":
      //   if (value === "") {
      //     error["shade"] = "* Please enter shade value";
      //   } else {
      //     error["shade"] = false;
      //   }
      //   break;
      // case "crown_angle":
      //   if (value === "") {
      //     error["crown_angle"] = "* Please enter crown_angle value";
      //   } else {
      //     error["crown_angle"] = false;
      //   }
      //   break;
      // case "crown_height":
      //   if (value === "") {
      //     error["crown_height"] = "* Please enter crown_height value";
      //   } else {
      //     error["crown_height"] = false;
      //   }
      //   break;
      // case "pavilion_angle":
      //   if (value === "") {
      //     error["pavilion_angle"] = "* Please enter pavilion_angle value";
      //   } else {
      //     error["pavilion_angle"] = false;
      //   }
      //   break;
      // case "pavilion_height":
      //   if (value === "") {
      //     error["pavilion_height"] = "* Please enter pavilion height value";
      //   } else {
      //     error["pavilion_height"] = false;
      //   }
      //   break;
      // case "white_table":
      //   if (value === "") {
      //     error["white_table"] = "* Please enter white table value";
      //   } else {
      //     error["white_table"] = false;
      //   }
      //   break;
      // case "white_side":
      //   if (value === "") {
      //     error["white_side"] = "* Please enter white side value";
      //   } else {
      //     error["white_side"] = false;
      //   }
      //   break;
      // case "table_black":
      //   if (value === "") {
      //     error["table_black"] = "* Please enter black table value";
      //   } else {
      //     error["table_black"] = false;
      //   }
      //   break;
      // case "side_black":
      //   if (value === "") {
      //     error["side_black"] = "* Please enter black side value";
      //   } else {
      //     error["side_black"] = false;
      //   }
      //   break;
      // case "table_open":
      //   if (value === "") {
      //     error["table_open"] = "* Please enter table open value";
      //   } else {
      //     error["table_open"] = false;
      //   }
      //   break;
      // case "pavilion_open":
      //   if (value === "") {
      //     error["pavilion_open"] = "* Please enter pavilion open value";
      //   } else {
      //     error["pavilion_open"] = false;
      //   }
      //   break;
      // case "crown_open":
      //   if (value === "") {
      //     error["crown_open"] = "* Please enter crown open value";
      //   } else {
      //     error["crown_open"] = false;
      //   }
      //   break;
      // case "girdle":
      //   if (value === "") {
      //     error["girdle"] = "* Please enter girdle value";
      //   } else {
      //     error["girdle"] = false;
      //   }
      //   break;
      // case "girdle_desc":
      //   if (value === "") {
      //     error["girdle_desc"] = "* Please enter girdle desc";
      //   } else {
      //     error["girdle_desc"] = false;
      //   }
      //   break;
      // case "culet":
      //   if (value === "") {
      //     error["culet"] = "* Please enter culet value";
      //   } else {
      //     error["culet"] = false;
      //   }
      //   break;
      // case "key_to_symbols":
      //   if (value === "") {
      //     error["key_to_symbols"] = "* Please enter key to symbols value";
      //   } else {
      //     error["key_to_symbols"] = false;
      //   }
      //   break;
      // case "treatment":
      //   if (value === "") {
      //     error["treatment"] = "* Please enter treatment value";
      //   } else {
      //     error["treatment"] = false;
      //   }
      //   break;
      // case "inscription":
      //   if (value === "") {
      //     error["inscription"] = "* Please enter inscription value";
      //   } else {
      //     error["inscription"] = false;
      //   }
      //   break;
      // case "comment":
      // if (value === "") {
      //   error["comment"] = "* Please enter comment";
      // } else {
      //   error["comment"] = false;
      // }
      // break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (state.stone_id === "") {
      setError((prev) => ({
        ...prev,
        stone_id: "* Please enter stone Id",
      }));
    }
    if (state.cert_no === "") {
      setError((prev) => ({
        ...prev,
        cert_no: "* Please enter certificate no.",
      }));
    }
    if (state.cert_type === "") {
      setError((prev) => ({
        ...prev,
        cert_type: "* Please enter cerificate type",
      }));
    }
    if (state.cert_url === "") {
      setError((prev) => ({
        ...prev,
        cert_url: "* Please enter certificate URL",
      }));
    }
    // if (state.image === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     image: "* Please enter Image URL",
    //   }));
    // }
    // if (state.video === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     video: "* Please enter Video URL",
    //   }));
    // }
    if (state.diamond_type === "") {
      setError((prev) => ({
        ...prev,
        diamond_type: "* Please Diamond Type",
      }));
    }
    // if (state.availability === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     availability: "* Please select Availability",
    //   }));
    // }
    if (state.carat === "") {
      setError((prev) => ({
        ...prev,
        carat: "* Please enter carat",
      }));
    }
    if (state.shape_id === "") {
      setError((prev) => ({
        ...prev,
        shape_id: "* Please select shape",
      }));
    }
    if (state.color_id === "") {
      setError((prev) => ({
        ...prev,
        color_id: "* Please select color",
      }));
    }
    if (state.colors_id === "") {
      setError((prev) => ({
        ...prev,
        colors_id: "* Please select fancy color",
      }));
    }
    if (state.overtone_id === "") {
      setError((prev) => ({
        ...prev,
        overtone_id: "* Please select overtone",
      }));
    }
    if (state.intensity_id === "") {
      setError((prev) => ({
        ...prev,
        intensity_id: "* Please select intensity",
      }));
    }
    if (state.clarity_id === "") {
      setError((prev) => ({
        ...prev,
        clarity_id: "* Please select clarity",
      }));
    }
    // if (state.cut_id === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     cut_id: "* Please select cut",
    //   }));
    // }
    if (state.polish_id === "") {
      setError((prev) => ({
        ...prev,
        polish_id: "* Please select polish",
      }));
    }
    if (state.symmetry_id === "") {
      setError((prev) => ({
        ...prev,
        symmetry_id: "* Please select symmentry",
      }));
    }
    if (state.fluorescence_id === "") {
      setError((prev) => ({
        ...prev,
        fluorescence_id: "* Please fluorescence",
      }));
    }
    // if (state.fluorescence_color_id === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     fluorescence_color_id: "* Please select fluorescence color",
    //   }));
    // }
    if (state.rapo_rate === "") {
      setError((prev) => ({
        ...prev,
        rapo_rate: "* Please enter rapo rate",
      }));
    }
    if (state.discount === "") {
      setError((prev) => ({
        ...prev,
        discount: "* Please enter discount",
      }));
    }
    if (state.rate === "") {
      setError((prev) => ({
        ...prev,
        rate: "* Please enter rate",
      }));
    }
    if (state.amount === "") {
      setError((prev) => ({
        ...prev,
        amount: "* Please enter amount",
      }));
    }
    if (state.table_per === "") {
      setError((prev) => ({
        ...prev,
        table_per: "* Please enter table percentage",
      }));
    }
    if (state.depth_per === "") {
      setError((prev) => ({
        ...prev,
        depth_per: "* Please enter depth  percentage",
      }));
    }
    if (state.length === "") {
      setError((prev) => ({
        ...prev,
        length: "* Please enter lenght",
      }));
    }
    if (state.width === "") {
      setError((prev) => ({
        ...prev,
        width: "* Please enter width",
      }));
    }
    if (state.height === "") {
      setError((prev) => ({
        ...prev,
        height: "* Please enter height",
      }));
    }
    // if (state.ratio === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     ratio: "* Please enter ratio",
    //   }));
    // }
    // if (state.bgm_id === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     bgm_id: "* Please select BGM id",
    //   }));
    // }
    if (state.city === "") {
      setError((prev) => ({
        ...prev,
        city: "* Please enter city",
      }));
    }
    if (state.country === "") {
      setError((prev) => ({
        ...prev,
        country: "* Please enter country",
      }));
    }
    if (state.growth_type === "") {
      setError((prev) => ({
        ...prev,
        growth_type: "* Please select Growth type",
      }));
    }
    // if (state.pair === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     pair: "* Please enter pair",
    //   }));
    // }
    // if (state.h_a === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     h_a: "* Please enter h_a",
    //   }));
    // }
    // if (state.milky === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     milky: "* Please enter milky value",
    //   }));
    // }
    // if (state.shade === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     shade: "* Please enter shade value",
    //   }));
    // }
    // if (state.crown_angle === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     crown_angle: "* Please enter crown angle value",
    //   }));
    // }
    // if (state.crown_height === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     crown_height: "* Please enter crown height value",
    //   }));
    // }
    // if (state.pavilion_angle === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     pavilion_angle: "* Please enter pavilion angle value",
    //   }));
    // }
    // if (state.pavilion_height === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     pavilion_height: "* Please enter pavilion height value",
    //   }));
    // }
    // if (state.table_open === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     table_open: "* Please enter table open value",
    //   }));
    // }
    // if (state.pavilion_open === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     pavilion_open: "* Please enter pavilion open value",
    //   }));
    // }
    // if (state.crown_open === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     crown_open: "* Please enter crown open value",
    //   }));
    // }
    // if (state.girdle === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     girdle: "* Please enter girdle value",
    //   }));
    // }
    // if (state.girdle_desc === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     girdle_desc: "* Please enter girdle desc",
    //   }));
    // }
    // if (state.culet === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     culet: "* Please enter culet value",
    //   }));
    // }
    // if (state.key_to_symbols === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     key_to_symbols: "* Please enter key to symbols value",
    //   }));
    // }
    // if (state.treatment === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     treatment: "* Please enter treatment value",
    //   }));
    // }
    // if (state.inscription === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     inscription: "* Please enter inscription value",
    //   }));
    // }
    // if (state.comment === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     comment: "* Please enter comment value",
    //   }));
    // }
    if (state.diamond_type !== "") {
      if (parseInt(state.diamond_type) === 1) {
        const _details = {
          stone_id: state.stone_id,
          cert_no: state.cert_no,
          cert_type: state.cert_type,
          cert_url: state.cert_url,
          carat: state.carat,
          shape_id: state.shape_id,
          color_id: state.color_id,
          colors_id: state.colors_id,
          overtone_id: state.overtone_id,
          intensity_id: state.intensity_id,
          clarity_id: state.clarity_id,
          polish_id: state.polish_id,
          symmetry_id: state.symmetry_id,
          fluorescence_id: state.fluorescence_id,
          rapo_rate: state.rapo_rate,
          discount: state.discount,
          rate: state.rate,
          amount: state.amount,
          table_per: state.table_per,
          depth_per: state.depth_per,
          country: state.country,
          length: state.depth_per,
          width: state.width,
          height: state.height,

          image: state.image,
          video: state.video,
          diamond_type: state.diamond_type,
          cut_id: state.cut_id,
          ratio: state.ratio,
          milky: state.milky,
          shade: state.shade,
          crown_angle: state.crown_angle,
          crown_height: state.crown_height,
          pavilion_angle: state.pavilion_angle,
          pavilion_height: state.pavilion_height,
          white_table: state.white_table,
          white_side: state.white_side,
          table_black: state.table_black,
          side_black: state.side_black,
          table_open: state.table_open,
          pavilion_open: state.pavilion_open,
          crown_open: state.crown_open,
          girdle: state.girdle,
          girdle_desc: state.girdle_desc,
          culet: state.culet,
          key_to_symbols: state.key_to_symbols,
          availability: state.availability,
          inscription: state.inscription,
          comment: state.comment,
          eye_clean: state.eye_clean,
        };
        const _error = {
          stone_id: error.stone_id,
          cert_no: error.cert_no,
          cert_type: error.cert_type,
          cert_url: error.cert_url,
          carat: error.carat,
          shape_id: error.shape_id,
          color_id: error.color_id,
          colors_id: error.colors_id,
          overtone_id: error.overtone_id,
          intensity_id: error.intensity_id,
          clarity_id: error.clarity_id,
          polish_id: error.polish_id,
          symmetry_id: error.symmetry_id,
          fluorescence_id: error.fluorescence_id,
          rapo_rate: error.rapo_rate,
          discount: error.discount,
          rate: error.rate,
          amount: error.amount,
          table_per: error.table_per,
          depth_per: error.depth_per,
          country: error.country,
          length: error.depth_per,
          width: error.width,
          height: error.height,

          // image: error.image,
          // video: error.video,
          // diamond_type: error.diamond_type,
          // cut_id: error.cut_id,
          // ratio: error.ratio,
          // milky: error.milky,
          // shade: error.shade,
          // crown_angle: error.crown_angle,
          // crown_height: error.crown_height,
          // pavilion_angle: error.pavilion_angle,
          // pavilion_height: error.pavilion_height,
          // white_table: error.white_table,
          // white_side: error.white_side,
          // table_black: error.table_black,
          // side_black: error.side_black,
          // table_open: error.table_open,
          // pavilion_open: error.pavilion_open,
          // crown_open: error.crown_open,
          // girdle: error.girdle,
          // girdle_desc: error.girdle_desc,
          // culet: error.culet,
          // key_to_symbols: error.key_to_symbols,
          // availability: error.availability,
          // inscription: error.inscription,
          // comment: error.comment,
        };
        const allFieldsValid = Object.keys(_error).map((e) => {
          if (state[e] === "") {
            return false;
          } else return true;
        });

        const notError = Object.keys(_error).map((e) => {
          if (!!error[e]) {
            return false;
          } else return true;
        });

        if (allFieldsValid.every((e) => e) && notError.every((e) => e)) {
          if (!!params.id) {
            props.updateStockList({
              details: {
                ..._details,
              },
              id: params.id,
            });
          } else {
            props.addStockList({
              ..._details,
            });
          }
        }
      } else if (parseInt(state.diamond_type) === 2) {
        const labGrownDetails = {
          stone_id: state.stone_id,
          carat: state.carat,
          shape_id: state.shape_id,
          color_id: state.color_id,
          colors_id: state.colors_id,
          overtone_id: state.overtone_id,
          intensity_id: state.intensity_id,
          clarity_id: state.clarity_id,
          rapo_rate: state.rapo_rate,
          discount: state.discount,
          rate: state.rate,
          amount: state.amount,
          city: state.city,
          country: state.country,
          growth_type: state.growth_type,

          cert_no: state.cert_no,
          cert_type: state.cert_type,
          cert_url: state.cert_url,
          image: state.image,
          video: state.video,
          diamond_type: state.diamond_type,
          cut_id: state.cut_id,
          polish_id: state.polish_id,
          symmetry_id: state.symmetry_id,
          fluorescence_id: state.fluorescence_id,
          table_per: state.table_per,
          depth_per: state.depth_per,
          length: state.depth_per,
          width: state.width,
          height: state.height,
          ratio: state.ratio,
          // size_id: state.size_id,
          bgm_id: state.bgm_id,
          fluorescence_color_id: state.fluorescence_color_id,
          pair: state.pair,
          h_a: state.h_a,
          eye_clean: state.eye_clean,
          availability: state.availability,
          treatment: state.treatment,
        };
        const labgrownError = {
          stone_id: error.stone_id,
          carat: error.carat,
          shape_id: error.shape_id,
          color_id: error.color_id,
          colors_id: error.colors_id,
          overtone_id: error.overtone_id,
          intensity_id: error.intensity_id,
          clarity_id: error.clarity_id,
          rapo_rate: error.rapo_rate,
          discount: error.discount,
          rate: error.rate,
          amount: error.amount,
          city: error.city,
          country: error.country,
          growth_type: error.growth_type,

          // cert_no: error.cert_no,
          // cert_type: error.cert_type,
          // cert_url: error.cert_url,
          // image: error.image,
          // video: error.video,
          // diamond_type: error.diamond_type,
          // cut_id: error.cut_id,
          // polish_id: error.polish_id,
          // symmetry_id: error.symmetry_id,
          // fluorescence_id: error.fluorescence_id,
          // table_per: error.table_per,
          // depth_per: error.depth_per,
          // length: error.depth_per,
          // width: error.width,
          // height: error.height,
          // ratio: error.ratio,
          // // size_id: error.size_id,
          // bgm_id: error.bgm_id,
          // fluorescence_color_id: error.fluorescence_color_id,
          // pair: error.pair,
          // h_a: error.h_a,
          // availability: error.availability,
          // treatment: error.treatment,
        };
        const allLabgrownFiled = Object.keys(labgrownError).map((e) => {
          if (state[e] === "") {
            return false;
          } else return true;
        });

        const labgrownNotError = Object.keys(labgrownError).map((e) => {
          if (!!error[e]) {
            return false;
          } else return true;
        });

        if (
          allLabgrownFiled.every((e) => e) &&
          labgrownNotError.every((e) => e)
        ) {
          if (!!params.id) {
            props.updateStockList({
              details: {
                ...labGrownDetails,
                measurement: `${labGrownDetails.length}*${labGrownDetails.width}*${labGrownDetails.height}`,
                eye_clean: labGrownDetails.eye_clean ? "YES" : "NO",
              },
              id: params.id,
            });
          } else {
            props.addStockList({
              ...labGrownDetails,
              measurement: `${labGrownDetails.length}*${labGrownDetails.width}*${labGrownDetails.height}`,
              eye_clean: labGrownDetails.eye_clean ? "YES" : "NO",
            });
          }
        }
      }
    } else {
      setError((prev) => ({
        ...prev,
        diamond_type: "* Please Diamond Type",
      }));
    }
  };

  const handleReset = () => {
    setState(initaialState);
    setError(initialError);
  };

  return (
    <div className="mb-5">
      <h4 className="my-4 fs-semi-bold">
        {!!params.id ? "Update" : "Add"} Diamond
      </h4>
      {!!details && (
        <div>
          <Row className="col-lg-8 g-3">
            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Stone ID</label>
              <input
                type="text"
                placeholder="Enter Stone ID"
                className="form-control"
                value={state?.stone_id}
                name={"stone_id"}
                onChange={handleInput}
              />
              {error.stone_id && (
                <h6 className="text-danger">{error.stone_id}</h6>
              )}
            </Col>
            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Certificate No</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Certificate No."
                value={state.cert_no}
                name={"cert_no"}
                onChange={handleInput}
              />
              {error.cert_no && (
                <h6 className="text-danger">{error.cert_no}</h6>
              )}
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <label className="form-label">Certificate Type</label>
              <select
                className="form-select"
                value={state.cert_type}
                name="cert_type"
                onChange={handleInput}
              >
                <option value="">Select Type</option>
                {Object.keys(details.Lab).map((e, i) => {
                  return (
                    <option value={e} key={i}>
                      {details.Lab[e]}
                    </option>
                  );
                })}
              </select>
              {error.cert_type && (
                <h6 className="text-danger">{error.cert_type}</h6>
              )}
            </Col>
            <Col lg={8} md={6} className="mb-3">
              <label className="form-label">Certificate URL</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Certificate URL"
                value={state.cert_url}
                name="cert_url"
                onChange={handleInput}
              />
              {error.cert_url && (
                <h6 className="text-danger">{error.cert_url}</h6>
              )}
            </Col>

            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Image URL"
                value={state.image}
                name="image"
                onChange={handleInput}
              />
              {error.image && <h6 className="text-danger">{error.image}</h6>}
            </Col>
            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Video URL</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Video URL"
                value={state.video}
                name="video"
                onChange={handleInput}
              />
              {error.video && <h6 className="text-danger">{error.video}</h6>}
            </Col>
            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Country</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Country"
                value={state.country}
                name="country"
                onChange={handleInput}
              />
              {error.country && (
                <h6 className="text-danger">{error.country}</h6>
              )}
            </Col>
            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Availability </label>
              <select
                className="form-select"
                value={state.availability}
                name="availability"
                onChange={handleInput}
              >
                <option value={""}>Select Availability</option>
                {availabilityList.map((e, i) => {
                  return (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  );
                })}
              </select>
              {error.availability && (
                <h6 className="text-danger">{error.availability}</h6>
              )}
            </Col>
            {parseInt(state.diamond_type) === 2 && (
              <Col lg={6} md={6} className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                  value={state.city}
                  name="city"
                  onChange={handleInput}
                />
                {error.city && <h6 className="text-danger">{error.city}</h6>}
              </Col>
            )}
            <div className="m-0 p-0"></div>

            <Col lg={6} md={6} className="mb-3">
              <label className="form-label">Diamond Type</label>
              <select
                className="form-select"
                value={state.diamond_type}
                name="diamond_type"
                onChange={handleInput}
                disabled={!!params.id}
              >
                <option value={""} disabled>
                  Select Diamond Type
                </option>
                {Object.keys(details.DiamondType).map((e, i) => {
                  return (
                    <option value={e} key={i}>
                      {details.DiamondType[e]}
                    </option>
                  );
                })}
              </select>
              {error.diamond_type && (
                <h6 className="text-danger">{error.diamond_type}</h6>
              )}
            </Col>
            <div className="m-0 p-0"></div>

            {state.diamond_type !== "" && (
              <>
                {/* {parseInt(state.diamond_type) === 2 && (
              <Col lg={6} md={6} className="mb-3">
                <label className="form-label">Size </label>
                <select
                  className="form-select"
                  value={state.size_id}
                  name="size_id"
                  onChange={handleInput}
                >
                  <option value={""}>Select Size</option>
                  {details.Size.map((e, i) => {
                    return (
                      <option value={e.id} key={i}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                {error.size_id && (
                  <h6 className="text-danger">{error.size_id}</h6>
                )}
              </Col>
            )} */}
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Shape</label>
                  <select
                    className="form-select"
                    value={state.shape_id}
                    name="shape_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Shape</option>
                    {details.Shape.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                  {error.shape_id && (
                    <h6 className="text-danger">{error.shape_id}</h6>
                  )}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Color </label>
                  <select
                    className="form-select"
                    value={state.color_id}
                    name="color_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Color</option>
                    {details.Color.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                  {error.color_id && (
                    <h6 className="text-danger">{error.color_id}</h6>
                  )}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Carat </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Carat"
                    value={state.carat}
                    name="carat"
                    // step="0.01"
                    onChange={handleInput}
                  />
                  {error.carat && (
                    <h6 className="text-danger">{error.carat}</h6>
                  )}
                </Col>
                {/*  fancy color */}
                <div className="fw-bold">Fancy Colors</div>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Colors</label>
                  <select
                    className="form-select"
                    value={state.colors_id}
                    name="colors_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Colors</option>
                    {details.FancyColor.map((e, i) => {
                      if (e.type === 0) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.colors_id && (
                    <h6 className="text-danger">{error.colors_id}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Overtone</label>
                  <select
                    className="form-select"
                    value={state.overtone_id}
                    name="overtone_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Overtone</option>
                    {details.FancyColor.map((e, i) => {
                      if (e.type === 1) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.overtone_id && (
                    <h6 className="text-danger">{error.overtone_id}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Intensity</label>
                  <select
                    className="form-select"
                    value={state.intensity_id}
                    name="intensity_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Intensity</option>
                    {details.FancyColor.map((e, i) => {
                      if (e.type === 2) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.intensity_id && (
                    <h6 className="text-danger">{error.intensity_id}</h6>
                  )}
                </Col>
                {/* ******* */}
                {/* clarity */}
                <div className="fw-bold">Clarity</div>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Clarity </label>
                  <select
                    className="form-select"
                    value={state.clarity_id}
                    name="clarity_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Clarity</option>
                    {details.Clarity.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                  {error.clarity_id && (
                    <h6 className="text-danger">{error.clarity_id}</h6>
                  )}
                </Col>
                {/* **** */}
                {/*  finish */}
                <div className="fw-bold">Finish</div>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Cut</label>
                  <select
                    className="form-select"
                    value={state.cut_id}
                    name="cut_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Cut</option>
                    {details.Finish.map((e, i) => {
                      if (e.type === 0) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.cut_id && (
                    <h6 className="text-danger">{error.cut_id}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Polish</label>
                  <select
                    className="form-select"
                    value={state.polish_id}
                    name="polish_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Polish</option>
                    {details.Finish.map((e, i) => {
                      if (e.type === 1) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.polish_id && (
                    <h6 className="text-danger">{error.polish_id}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Symmetry</label>
                  <select
                    className="form-select"
                    value={state.symmetry_id}
                    name="symmetry_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Symmetry</option>
                    {details.Finish.map((e, i) => {
                      if (e.type === 2) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.symmetry_id && (
                    <h6 className="text-danger">{error.symmetry_id}</h6>
                  )}
                </Col>
                {/* ******* */}
                {/*  flurences */}
                <div className="fw-bold">Fluorescence</div>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Fluorescence</label>
                  <select
                    className="form-select"
                    value={state.fluorescence_id}
                    name="fluorescence_id"
                    onChange={handleInput}
                  >
                    <option value={""}>Select Fluorescence</option>
                    {details.Fluorescence.map((e, i) => {
                      if (e.type === 0) {
                        return (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        );
                      } else return;
                    })}
                  </select>
                  {error.fluorescence_id && (
                    <h6 className="text-danger">{error.fluorescence_id}</h6>
                  )}
                </Col>
                {parseInt(state.diamond_type) === 2 && (
                  <Col lg={6} md={6} className="mb-3">
                    <label className="form-label">Fluorescence Color</label>
                    <select
                      className="form-select"
                      value={state.fluorescence_color_id}
                      name="fluorescence_color_id"
                      onChange={handleInput}
                    >
                      <option value={""}>Select Fluorescence Color</option>
                      {details.Fluorescence.map((e, i) => {
                        if (e.type === 1) {
                          return (
                            <option value={e.id} key={i}>
                              {e.name}
                            </option>
                          );
                        } else return;
                      })}
                    </select>
                    {error.fluorescence_color_id && (
                      <h6 className="text-danger">
                        {error.fluorescence_color_id}
                      </h6>
                    )}
                  </Col>
                )}
                {parseInt(state.diamond_type) === 2 && (
                  <Col lg={6} md={6} className="mb-3">
                    <label className="form-label">BGM</label>
                    <select
                      className="form-select"
                      value={state.bgm_id}
                      name="bgm_id"
                      onChange={handleInput}
                    >
                      <option value={""}>Select BGM</option>
                      {details.Fluorescence.map((e, i) => {
                        if (e.type === 2) {
                          return (
                            <option value={e.id} key={i}>
                              {e.name}
                            </option>
                          );
                        } else return;
                      })}
                    </select>
                    {error.bgm_id && (
                      <h6 className="text-danger">{error.bgm_id}</h6>
                    )}
                  </Col>
                )}
                {/* ******* */}
                <div className="fw-bold">Details</div>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Rapo rate</label>
                  <input
                    type="text"
                    placeholder="Enter Rapo Rate"
                    className="form-control"
                    value={state.rapo_rate}
                    name="rapo_rate"
                    onChange={handleInput}
                  />
                  {error.rapo_rate && (
                    <h6 className="text-danger">{error.rapo_rate}</h6>
                  )}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Discount</label>
                  <input
                    type="text"
                    placeholder="Enter Discount"
                    className="form-control"
                    value={state.discount}
                    name="discount"
                    onChange={handleInput}
                  />
                  {error.discount && (
                    <h6 className="text-danger">{error.discount}</h6>
                  )}
                </Col>

                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Rate</label>
                  <input
                    type="text"
                    placeholder="Enter Rate"
                    className="form-control"
                    value={state.rate}
                    name="rate"
                    onChange={handleInput}
                  />
                  {error.rate && <h6 className="text-danger">{error.rate}</h6>}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Amount</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control"
                    value={state.amount}
                    name="amount"
                    onChange={handleInput}
                  />
                  {error.amount && (
                    <h6 className="text-danger">{error.amount}</h6>
                  )}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Table per</label>
                  <input
                    type="text"
                    placeholder="Enter Table per"
                    className="form-control"
                    value={state.table_per}
                    name="table_per"
                    onChange={handleInput}
                  />
                  {error.table_per && (
                    <h6 className="text-danger">{error.table_per}</h6>
                  )}
                </Col>

                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Depth per</label>
                  <input
                    type="text"
                    placeholder="Enter Depth per"
                    className="form-control"
                    value={state.depth_per}
                    name="depth_per"
                    onChange={handleInput}
                  />
                  {error.depth_per && (
                    <h6 className="text-danger">{error.depth_per}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Length</label>
                  <input
                    type="text"
                    placeholder="Enter Lenght"
                    className="form-control"
                    value={state.length}
                    name="length"
                    onChange={handleInput}
                  />
                  {error.length && (
                    <h6 className="text-danger">{error.length}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Width</label>
                  <input
                    type="text"
                    placeholder="Enter Width"
                    className="form-control"
                    value={state.width}
                    name="width"
                    onChange={handleInput}
                  />
                  {error.width && (
                    <h6 className="text-danger">{error.width}</h6>
                  )}
                </Col>
                <Col lg={4} md={4} className="mb-3">
                  <label className="form-label">Height</label>
                  <input
                    type="text"
                    placeholder="Enter Height"
                    className="form-control"
                    value={state.height}
                    name="height"
                    onChange={handleInput}
                  />
                  {error.height && (
                    <h6 className="text-danger">{error.height}</h6>
                  )}
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <label className="form-label">Ratio</label>
                  <input
                    type="text"
                    placeholder="Enter Ratio"
                    className="form-control"
                    value={state.ratio}
                    name="ratio"
                    onChange={handleInput}
                  />
                  {error.ratio && (
                    <h6 className="text-danger">{error.ratio}</h6>
                  )}
                </Col>
                {/* pair and  h_a groth type */}
                {parseInt(state.diamond_type) === 2 && (
                  <>
                    <div className="m-0 p-0"></div>

                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Pair</label>
                      <input
                        type="text"
                        placeholder="Enter Pair"
                        className="form-control"
                        value={state?.pair}
                        name={"pair"}
                        onChange={handleInput}
                      />
                      {error.pair && (
                        <h6 className="text-danger">{error.pair}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">H_A</label>
                      <input
                        type="text"
                        placeholder="Enter h_a"
                        className="form-control"
                        value={state?.h_a}
                        name={"h_a"}
                        onChange={handleInput}
                      />
                      {error.h_a && (
                        <h6 className="text-danger">{error.h_a}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Growth type</label>
                      <select
                        className="form-select"
                        value={state.growth_type}
                        name="growth_type"
                        onChange={handleInput}
                      >
                        <option value={""}>Select Growth type</option>
                        {Object.keys(details.Type).map((e, i) => {
                          return (
                            <option value={e} key={i}>
                              {details.Type[e]}
                            </option>
                          );
                        })}
                      </select>
                      {error.growth_type && (
                        <h6 className="text-danger">{error.growth_type}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Treatment</label>
                      <input
                        type="text"
                        placeholder="Enter treatment"
                        className="form-control"
                        value={state?.treatment}
                        name={"treatment"}
                        onChange={handleInput}
                      />
                      {error.treatment && (
                        <h6 className="text-danger">{error.treatment}</h6>
                      )}
                    </Col>
                  </>
                )}
                {/* natural dimaond details */}
                {parseInt(state.diamond_type) === 1 && (
                  <>
                    <div className="m-0 p-0"></div>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Milky </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.milky}
                        name="milky"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.milky && (
                        <h6 className="text-danger">{error.milky}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Shade </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.shade}
                        name="shade"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.shade && (
                        <h6 className="text-danger">{error.shade}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Crown Angle</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.crown_angle}
                        name="crown_angle"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.crown_angle && (
                        <h6 className="text-danger">{error.crown_angle}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Crown Height</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.crown_height}
                        name="crown_height"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.crown_height && (
                        <h6 className="text-danger">{error.crown_height}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Pavilion Angle</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.pavilion_angle}
                        name="pavilion_angle"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.pavilion_angle && (
                        <h6 className="text-danger">{error.pavilion_angle}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Pavilion Height</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.pavilion_height}
                        name="pavilion_height"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.pavilion_height && (
                        <h6 className="text-danger">{error.pavilion_height}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">White Table</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.white_table}
                        name={"white_table"}
                        onChange={handleInput}
                      />
                      {error.white_table && (
                        <h6 className="text-danger">{error.white_table}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">White Side</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.white_side}
                        name={"white_side"}
                        onChange={handleInput}
                      />
                      {error.white_side && (
                        <h6 className="text-danger">{error.white_side}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Black Table</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.table_black}
                        name={"table_black"}
                        onChange={handleInput}
                      />
                      {error.table_black && (
                        <h6 className="text-danger">{error.table_black}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Black Side</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.side_black}
                        name={"side_black"}
                        onChange={handleInput}
                      />
                      {error.side_black && (
                        <h6 className="text-danger">{error.side_black}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Table Open</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.table_open}
                        name={"table_open"}
                        onChange={handleInput}
                      />
                      {error.table_open && (
                        <h6 className="text-danger">{error.table_open}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Pavilion Open</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.pavilion_open}
                        name={"pavilion_open"}
                        onChange={handleInput}
                      />
                      {error.pavilion_open && (
                        <h6 className="text-danger">{error.pavilion_open}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Crown Open</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.crown_open}
                        name={"crown_open"}
                        onChange={handleInput}
                      />
                      {error.crown_open && (
                        <h6 className="text-danger">{error.crown_open}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Girdle</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                        value={state.girdle}
                        name="girdle"
                        // step="0.01"
                        onChange={handleInput}
                      />
                      {error.girdle && (
                        <h6 className="text-danger">{error.girdle}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Girdle Desc</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.girdle_desc}
                        name={"girdle_desc"}
                        onChange={handleInput}
                      />
                      {error.girdle_desc && (
                        <h6 className="text-danger">{error.girdle_desc}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Culet</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.culet}
                        name={"culet"}
                        onChange={handleInput}
                      />
                      {error.culet && (
                        <h6 className="text-danger">{error.culet}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Key to Symbols</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.key_to_symbols}
                        name={"key_to_symbols"}
                        onChange={handleInput}
                      />
                      {error.key_to_symbols && (
                        <h6 className="text-danger">{error.key_to_symbols}</h6>
                      )}
                    </Col>
                    <div className="m-0 p-0"></div>

                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Inscription</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.inscription}
                        name={"inscription"}
                        onChange={handleInput}
                      />
                      {error.inscription && (
                        <h6 className="text-danger">{error.inscription}</h6>
                      )}
                    </Col>
                    <Col lg={6} md={6} className="mb-3">
                      <label className="form-label">Comment</label>
                      <input
                        type="text"
                        placeholder="Enter value"
                        className="form-control"
                        value={state?.comment}
                        name={"comment"}
                        onChange={handleInput}
                      />
                      {error.comment && (
                        <h6 className="text-danger">{error.comment}</h6>
                      )}
                    </Col>
                  </>
                )}

                {/*  EYE CLEAN */}
                <div className="m-0 p-0"></div>
                <Col lg={6} md={6} className="mb-3">
                  <input
                    className="me-3 form-check-input"
                    type="checkbox"
                    // value={state.eye_clean}
                    id="flexCheckChecked"
                    checked={state.eye_clean}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        eye_clean: e.target.checked,
                      }));
                    }}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    Eye Cleaning
                  </label>
                </Col>
              </>
            )}
          </Row>
          <div>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {!!params.id ? "Update" : "Submit"}
            </button>
            <button className="btn btn-secondary ms-3" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProp = (state) => ({
  StockListReducer: state.masterListReducer.StockList,
  getAllMastersListReducer: state.masterListReducer.getAllMastersList,
  addStockListReducer: state.masterListReducer.addStockList,
  updateStockListReducer: state.masterListReducer.updateStockList,
});

const mapDispatchToProps = (dispatch) => ({
  getAllMastersList: () => dispatch(getAllMastersList()),
  addStockList: (details) => dispatch(addStockList(details)),
  getStockList: () => dispatch(getStockList()),
  updateStockList: (details) => dispatch(updateStockList(details)),
  resetUpdateStockList: (id) => dispatch({ type: RESET_UPDATE_STOCK_LIST }),
  resetAddStockList: (id) => dispatch({ type: RESET_ADD_STOCK_LIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddSingleDiamond);
