import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Sidebar(props) {
  const getPath = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <aside>
        <ul>
          <li>
            <Link
              to="/dashboard"
              className={getPath.pathname === "/dashboard" ? "active" : ""}
            >
              <i className="ri-dashboard-line"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/upload-diamonds"
              className={
                getPath.pathname === "/upload-diamonds" ? "active" : ""
              }
            >
              <i className="ri-eye-line"></i> Upload Diamond
            </Link>
          </li>
          <li>
            <Link
              to="/invalid-stones"
              className={getPath.pathname === "/invalid-stones" ? "active" : ""}
            >
              <i className="ri-file-list-3-line"></i> Invalid Stones
            </Link>
          </li>
          <li>
            <Link
              to="/upload-history"
              className={getPath.pathname === "/upload-history" ? "active" : ""}
            >
              <i className="ri-file-list-3-line"></i> Upload History
            </Link>
          </li>
          <li>
            <Link
              to="/hold-stones"
              className={getPath.pathname === "/hold-stones" ? "active" : ""}
            >
              <i className="ri-price-tag-3-line"></i> Hold List
            </Link>
          </li>
          <li>
            <Link
              to="/confirm-stones"
              className={getPath.pathname === "/confirm-stones" ? "active" : ""}
            >
              <i className="ri-shield-check-line"></i> Confirmed List
            </Link>
          </li>
          <li>
            <Link
              to="/instruction"
                className={getPath.pathname === "/instruction" ? "active" : ""}
            >
              <i className="ri-logout-box-line"></i> Instruction
            </Link>
          </li>
          <li>
            <Link
              href="/"
              onClick={() => props.setIsLogout(true)}
              //   className={getPath.pathname === "/login" ? "active" : ""}
            >
              <i className="ri-logout-box-line"></i> Logout
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
