
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import LoginPage from './Pages/LoginPage';
import { useEffect } from "react";
import Dashboard from "./Pages/Dashboard";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { connect } from "react-redux";
import { getProfile } from "./Services/Actions/authAction";
import { toast } from "react-toastify";
import UserProfile from "./Pages/UserProfile";
import ChangePassword from "./Pages/ChangePassword";
import InvalidStone from "./Pages/InvalidStone";

import '@progress/kendo-theme-default/dist/all.css';
import HoldStone from "./Pages/HoldStone";
import ConfirmStone from "./Pages/ConfirmStone";
import ForgotPassword from "./Pages/ForgotPassword";
import UploadHistory from "./Pages/UploadHistory";
import UploadDiamonds from "./Pages/Upload_Stones/UploadDiamonds";
import AddDiamonds from "./Pages/Upload_Stones/AddDiamonds";
import ErrorPage from "./Pages/ErrorPage";
import InstructionPage from "./Pages/InstructionPage";


function App(props) {

  const location = useLocation()
  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (token !== null) {
      props.getProfile();
    }
  }, []);

  useEffect(() => {
    if (props.loginReducer.success) {
      props.getProfile();
      navigate("/dashboard");
      toast.success("Login SuccessFully..");
    }
  }, [props.loginReducer]);

  useEffect(() => {
    // if ((location.pathname === '' || location.pathname === '/') && token === null) {
    //   navigate('/login')
    // } else if ((location.pathname === '' || location.pathname === '/') && token !== null) {
    //   navigate('/dashboard')
    // }
    if (token === null) {
      // if((location.pathname === '' || location.pathname === '/')) {
      //   navigate('/login')
      // }
      if (location.pathname !== '/forgot-password' && location.pathname !== '/login') {
        navigate('/login')
      }
    } else {
      if ((location.pathname === '' || location.pathname === '/')) {
        navigate('/dashboard')
      } else if (location.pathname === '/login') {
        navigate('/dashboard')
      }
    }
  }, [location.pathname])

  return (
    <div className="App">
      {/* <Routes>
        {
          token === null ?
            <>
              <Route Exact path="/login" element={<LoginPage />} />
            </> :
            <>
              <Route Exact path="/dashboard" element={<Dashboard />} />
            </>
        }
      </Routes> */}
      {
        token === null ?
          <Routes>
            <Route Exact path="/login" element={<LoginPage />} />
            <Route Exact path="/forgot-password" element={<ForgotPassword />} />
          </Routes> :
          <>
            <Header />
            <main className="userMain">
              <Routes>
                <Route Exact path="/dashboard" element={<Dashboard />} />
                <Route Exact path="/profile" element={<UserProfile />} />
                <Route Exact path="/change-password" element={<ChangePassword />} />
                <Route Exact path="/invalid-stones" element={<InvalidStone />} />
                <Route Exact path="/hold-stones" element={<HoldStone />} />
                <Route Exact path="/confirm-stones" element={<ConfirmStone />} />
                <Route Exact path="/upload-history" element={<UploadHistory />} />
                <Route Exact path="/upload-diamonds" element={<UploadDiamonds />} />
                <Route Exact path="/upload-diamonds/add" element={<AddDiamonds />} />
                <Route Exact path="/upload-diamonds/update/:id" element={<AddDiamonds />} />
                <Route Exact path="/instruction" element={<InstructionPage />} />
                <Route Exact path="*" element={<ErrorPage />} />

              </Routes>
            </main>
            <Footer />
          </>
      }
    </div>
  );
}


const mapStateToProps = (state) => ({
  getProfileReducer: state.authReducer.getProfile,
  loginReducer: state.authReducer.login,
});
const mapDispatchToProps = (dispatch) => ({
  getProfile: (payload) => dispatch(getProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

