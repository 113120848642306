import { combineReducers } from "redux";
import { RESET_STORE } from "../Constant";

import authReducer from './authReducer'
import dashboardReducer from './dashboardReducer'
import masterListReducer from './masterListReducer'

const appReducer = combineReducers({
  authReducer,
  dashboardReducer,
  masterListReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
