import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { changeStockListStatus } from "../Services/Actions/masterListAction";
import { toast } from "react-toastify";
import { RESET_STOCK_LIST_STATUS } from "../Services/Constant";
import { useNavigate } from "react-router-dom";

function NaturalDiamondTable(props) {
  const navigate = useNavigate();
  const [state, setState] = useState([]);

  useEffect(() => {
    const list = props.state.filter((e) => {
      return parseInt(e.diamond_type) === 1;
    });
    setState([...list]);
  }, [props.state]);
  
  const MyCustomCell = (cell) => {
    return (
      <td>
        <div
          className="text-center pointer"
          onClick={() => {
            navigate(`/upload-diamonds/update/${cell.dataItem.id}`);
          }}
        >
          <i className="fa-regular fa-pen-to-square"></i>
        </div>
      </td>
    );
  };

  console.log("state", state);
  const customStatus = (cell) => {
    return (
      <td className="d-flex justify-content-center align-items-center ">
        <span
          className={classNames("text-center py-1 btn pointer", {
            "btn-primary": cell.dataItem.status === "Active",
            "btn-secondary": cell.dataItem.status === "Deactive",
          })}
          style={{
            borderRadius: "10px",
            width: "90px",
          }}
          onClick={() => {
            const list = state.map((e) => {
              if (e.id === cell.dataItem.id) {
                return {
                  ...e,
                  status:
                    cell.dataItem.status === "Active" ? "Deactive" : "Active",
                };
              } else {
                return {
                  ...e,
                };
              }
            });
            setState([...list]);
            props.changeStockListStatus({
              id: cell.dataItem.id,
              details: {
                status: cell.dataItem.status === "Active" ? 2 : 1,
              },
            });
          }}
        >
          {cell.dataItem.status}
        </span>
      </td>
    );
  };

  // useEffect(() => {
  //   if (props.statusStockListReducer.success) {
  //     // toast.success("Status changed successfull...");
  //     // props.resetStatusStockList();
  //   }
  // }, [props.statusStockListReducer]);
  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      headerCellRender={(props) => <th className="fs-semi bold">{props}</th>}
      data={state}
      dataItemKey={"id"}
    >
      <GridColumn field="stone_id" title="Stone Id" />
      <GridColumn field="cert_no" title="Certificate" />
      <GridColumn field="cert" title="Certificate Type" />
      <GridColumn field="status" title="Status" cell={customStatus} />
      <GridColumn field="id" title="Action" cell={MyCustomCell} />
    </Grid>
  );
  return <div className="mt-4">{grid}</div>;
}

const mapStateToProps = (state) => ({
  statusStockListReducer: state.masterListReducer.statusStockList,
});
const mapDispatchToProps = (dispatch) => ({
  changeStockListStatus: (details) => dispatch(changeStockListStatus(details)),
  resetStatusStockList: () => dispatch({ type: RESET_STOCK_LIST_STATUS }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NaturalDiamondTable);
