import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UploadCsv from "./UploadCsv";
import AddSingleDiamond from "./AddSingleDiamond";

function AddDiamonds() {
  const params = useParams();
  return (
    <div>
      <div className="page-top-line mt-5">
        <h2 className="page-title">
          {!!params.id ? "Update" : "Add"} Diamond Details
        </h2>
      </div>
      <div>
        <div className="mt-4">
          <Tabs
            defaultActiveKey={!params.id ? "home" : "profile"}
            id="AddStockTabs"
          >
            {!params.id && (
              <Tab eventKey="home" title="Upload CSV">
                <UploadCsv />
              </Tab>
            )}
            <Tab
              eventKey="profile"
              title={`${!!params.id ? "Update" : "Add"} Product`}
            >
              <AddSingleDiamond />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default AddDiamonds;
