import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@progress/kendo-theme-default/dist/all.css';
import '../src/assets/css/index.scss'
import '../src/assets/css/App.scss'
import '../src/assets/css/style.scss'
import '../src/assets/css/index.css';
import App from './App';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import { ToastContainer } from 'react-toastify';
import store from './Services/Store';


const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="fa fa-close notifications-close text-danger" />

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        closeOnClick
        closeButton={<CloseButton />}
      />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
