import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllMastersList,
  invalidStoneList,
  saveCsvFile,
} from "../Services/Actions/masterListAction";
import {
  GET_COUNTRY_LIST_ERROR,
  INVALID_STONE_LIST_RESET,
} from "../Services/Constant";
import Loader from "../Components/Loader";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getCountryList } from "../Services/Actions/authAction";
import _ from "lodash";

function InvalidStone(props) {
  const [diamondType, setDiamodType] = useState("1");
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState([]);
  const [error, setError] = useState([]);

  const [masters, setMasters] = useState(null);
  const [countryList, setCountryList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [currentDetails, setCurrentDetails] = useState(null);
  const [currentError, setCurrentError] = useState(null);

  useEffect(() => {
    if (diamondType !== "") {
      props.invalidStoneList(diamondType);
      setLoading(true);
    } else {
      props.resetList();
    }
  }, [diamondType]);

  useEffect(() => {
    props.getCountryList();
    props.getAllMastersList();
  }, []);

  useEffect(() => {
    if (props.getCountryReducer.success) {
      const data = props.getCountryReducer.data;
      setCountryList([...data.country]);
      console.log("?", props.getCountryReducer.data);
    }
  }, [props.getCountryReducer]);

  useEffect(() => {
    if (props.getAllMastersListReducer.success) {
      const data = props.getAllMastersListReducer.data;
      setMasters(data);
    }
  }, [props.getAllMastersListReducer]);

  useEffect(() => {
    if (props.invalidStoneReducer.success) {
      const data = props.invalidStoneReducer.data;
      if (parseInt(diamondType) === 1) {
        const errorList = data.map((e) => {
          return {
            id: e.id,
            uuid: e.uuid,
            cert_no: e.cert_no_error !== "" ? e.cert_no_error : false,
            cert_type: e.cert_type_error !== "" ? e.cert_type_error : false,
            // cert_url: e.cert_url_error !== '' ? e.cert_url_error : false,
            amount: e.amount_error !== "" ? e.amount_error : false,
            carat: e.carat_error !== "" ? e.carat_error : false,
            clarity_id: e.clarity_id_error !== "" ? e.clarity_id_error : false,
            color_id: e.color_id_error !== "" ? e.color_id_error : false,
            colors_id: e.colors_id_error !== "" ? e.colors_id_error : false,
            country: e.country_error !== "" ? e.country_error : false,
            discount: e.discount_error !== "" ? e.discount_error : false,
            intensity_id:
              e.intensity_id_error !== "" ? e.intensity_id_error : false,
            overtone_id:
              e.overtone_id_error !== "" ? e.overtone_id_error : false,
            rapo_rate: e.rapo_rate_error !== "" ? e.rapo_rate_error : false,
            rate: e.rate_error !== "" ? e.rate_error : false,
            shape_id: e.shape_id_error !== "" ? e.shape_id_error : false,
            stone_id: e.stone_id_error !== "" ? e.stone_id_error : false,
            polish_id: e.polish_id_error !== "" ? e.polish_id_error : false,
            symmetry_id:
              e.symmetry_id_error !== "" ? e.symmetry_id_error : false,
            fluorescence_id:
              e.fluorescence_id_error !== "" ? e.fluorescence_id_error : false,
            table_per: e.table_per_error !== "" ? e.table_per_error : false,
            depth_per: e.depth_per_error !== "" ? e.depth_per_error : false,
            length: e.length_error !== "" ? e.length_error : false,
            width: e.width_error !== "" ? e.width_error : false,
            height: e.height_error !== "" ? e.height_error : false,
          };
        });

        const list = data.map((e) => {
          const reason = errorList.find((list) => list.id === e.id);
          const filter = Object.keys(reason)
            .filter((e) => {
              if (!!reason[e] && e !== "id" && e !== "uuid") {
                return e;
              }
            })
            .map((e) => {
              return reason[e];
            });

          return {
            id: e.id,
            reason: filter,
            stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
            cert_no: !!e.cert_no && e.cert_no !== "" ? e.cert_no : "N/A",
            cert_type:
              !!e.cert_type && e.cert_type !== "" ? e.cert_type : "N/A",
            carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
            shape_id: !!e.shape_id && e.shape_id !== "" ? e.shape_id : "N/A",
            color_id: !!e.color_id && e.color_id !== "" ? e.color_id : "N/A",
            colors_id:
              !!e.colors_id && e.colors_id !== "" ? e.colors_id : "N/A",
            overtone_id:
              !!e.overtone_id && e.overtone_id !== "" ? e.overtone_id : "N/A",
            intensity_id:
              !!e.intensity_id && e.intensity_id !== ""
                ? e.intensity_id
                : "N/A",
            clarity_id:
              !!e.clarity_id && e.clarity_id !== "" ? e.clarity_id : "N/A",
            polish_id:
              !!e.polish_id && e.polish_id !== "" ? e.polish_id : "N/A",
            symmetry_id:
              !!e.symmetry_id && e.symmetry_id !== "" ? e.symmetry_id : "N/A",
            fluorescence_id:
              !!e.fluorescence_id && e.fluorescence_id !== ""
                ? e.fluorescence_id
                : "N/A",
            rapo_rate:
              !!e.rapo_rate && e.rapo_rate !== "" ? e.rapo_rate : "N/A",
            discount: !!e.discount && e.discount !== "" ? e.discount : "N/A",
            rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
            amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
            table_per:
              !!e.table_per && e.table_per !== "" ? e.table_per : "N/A",
            depth_per:
              !!e.depth_per && e.depth_per !== "" ? e.depth_per : "N/A",
            country: !!e.country && e.country !== "" ? e.country : "N/A",
            length: !!e.depth_per && e.depth_per !== "" ? e.depth_per : "N/A",
            width: !!e.width && e.width !== "" ? e.width : "N/A",
            height: !!e.height && e.height !== "" ? e.height : "N/A",
            uuid: e.uuid,
          };
        });
        console.log("list", list);
        setState(list);
        setError(errorList);
      } else if (parseInt(diamondType) === 2) {
        const errorList = data.map((e) => {
          return {
            id: e.id,
            uuid: e.uuid,
            amount: e.amount_error !== "" ? e.amount_error : false,
            carat: e.carat_error !== "" ? e.carat_error : false,
            city: e.city_error !== "" ? e.city_error : false,
            clarity_id: e.clarity_id_error !== "" ? e.clarity_id_error : false,
            color_id: e.color_id_error !== "" ? e.color_id_error : false,
            colors_id: e.colors_id_error !== "" ? e.colors_id_error : false,
            country: e.country_error !== "" ? e.country_error : false,
            discount: e.discount_error !== "" ? e.discount_error : false,
            growth_type:
              e.growth_type_error !== "" ? e.growth_type_error : false,
            intensity_id:
              e.intensity_id_error !== "" ? e.intensity_id_error : false,
            overtone_id:
              e.overtone_id_error !== "" ? e.overtone_id_error : false,
            rapo_rate: e.rapo_rate_error !== "" ? e.rapo_rate_error : false,
            rate: e.rate_error !== "" ? e.rate_error : false,
            shape_id: e.shape_id_error !== "" ? e.shape_id_error : false,
            stone_id: e.stone_id_error !== "" ? e.stone_id_error : false,
          };
        });

        const list = data.map((e) => {
          const reason = errorList.find((list) => list.id === e.id);
          const filter = Object.keys(reason)
            .filter((e) => {
              if (!!reason[e] && e !== "id" && e !== "uuid") {
                return e;
              }
            })
            .map((e) => {
              return reason[e];
            });
          return {
            id: e.id,
            uuid: e.uuid,
            reason: filter,
            stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
            carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
            shape_id: !!e.shape_id && e.shape_id !== "" ? e.shape_id : "N/A",
            color_id: !!e.color_id && e.color_id !== "" ? e.color_id : "N/A",
            colors_id:
              !!e.colors_id && e.colors_id !== "" ? e.colors_id : "N/A",
            overtone_id:
              !!e.overtone_id && e.overtone_id !== "" ? e.overtone_id : "N/A",
            intensity_id:
              !!e.intensity_id && e.intensity_id !== ""
                ? e.intensity_id
                : "N/A",
            clarity_id:
              !!e.clarity_id && e.clarity_id !== "" ? e.clarity_id : "N/A",
            rapo_rate:
              !!e.rapo_rate && e.rapo_rate !== "" ? e.rapo_rate : "N/A",
            discount: !!e.discount && e.discount !== "" ? e.discount : "N/A",
            rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
            amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
            city: !!e.city && e.city !== "" ? e.city : "N/A",
            country: !!e.country && e.country !== "" ? e.country : "N/A",
            growth_type:
              !!e.growth_type && e.growth_type !== "" ? e.growth_type : "N/A",
          };
        });

        setState(list);
        setError(errorList);
      }
      // props.resetList();
      setLoading(false);
    }
  }, [props.invalidStoneReducer, isOpen]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (
      name === "carat" ||
      name === "height" ||
      name === "width" ||
      name === "length"
    ) {
      if (RegExp(/^([0-9]{0,5}([.][0-9]{0,2})?)$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else if (name === "rapo_rate" || name === "rate" || name === "amount") {
      if (RegExp(/^([0-9]{0,10}([.][0-9]{0,2})?)$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else if (name === "discount") {
      if (RegExp(/^([0-9]{0,2}([.][0-9]{0,2})?|100)$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else if (name === "stone_id") {
      if (RegExp(/^([a-zA-Z0-9]{0,12})$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else if (name === "cert_no") {
      if (RegExp(/^([0-9]{0,12})$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else if (name === "city") {
      if (RegExp(/^[a-zA-Z]+$/i).test(value)) {
        setCurrentDetails((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else return;
    } else {
      setCurrentDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "stone_id":
        if (value === "") {
          currentError["stone_id"] = "Required";
        } else {
          currentError["stone_id"] = false;
        }
        break;

      case "cert_no":
        if (value === "") {
          currentError["cert_no"] = "Required";
        } else {
          currentError["cert_no"] = false;
        }
        break;

      case "cert_type":
        if (value === "") {
          currentError["cert_type"] = "Required";
        } else {
          currentError["cert_type"] = false;
        }
        break;

      case "carat":
        if (value === "") {
          currentError["carat"] = "Required";
        } else {
          currentError["carat"] = false;
        }
        break;

      case "shape_id":
        if (value === "") {
          currentError["shape_id"] = "Required";
        } else {
          currentError["shape_id"] = false;
        }
        break;

      case "color_id":
        if (value === "") {
          currentError["color_id"] = "Required";
        } else {
          currentError["color_id"] = false;
        }
        break;

      case "colors_id":
        if (value === "") {
          currentError["colors_id"] = "Required";
        } else {
          currentError["colors_id"] = false;
        }
        break;

      case "overtone_id":
        if (value === "") {
          currentError["overtone_id"] = "Required";
        } else {
          currentError["overtone_id"] = false;
        }
        break;

      case "intensity_id":
        if (value === "") {
          currentError["intensity_id"] = "Required";
        } else {
          currentError["intensity_id"] = false;
        }
        break;

      case "clarity_id":
        if (value === "") {
          currentError["clarity_id"] = "Required";
        } else {
          currentError["clarity_id"] = false;
        }
        break;

      case "polish_id":
        if (value === "") {
          currentError["polish_id"] = "Required";
        } else {
          currentError["polish_id"] = false;
        }
        break;

      case "symmetry_id":
        if (value === "") {
          currentError["symmetry_id"] = "Required";
        } else {
          currentError["symmetry_id"] = false;
        }
        break;

      case "fluorescence_id":
        if (value === "") {
          currentError["fluorescence_id"] = "Required";
        } else {
          currentError["fluorescence_id"] = false;
        }
        break;

      case "rapo_rate":
        if (value === "") {
          currentError["rapo_rate"] = "Required";
        } else {
          currentError["rapo_rate"] = false;
        }
        break;

      case "discount":
        if (value === "") {
          currentError["discount"] = "Required";
        } else {
          currentError["discount"] = false;
        }
        break;

      case "rate":
        if (value === "") {
          currentError["rate"] = "Required";
        } else {
          currentError["rate"] = false;
        }
        break;

      case "amount":
        if (value === "") {
          currentError["amount"] = "Required";
        } else {
          currentError["amount"] = false;
        }
        break;

      case "table_per":
        if (value === "") {
          currentError["table_per"] = "Required";
        } else {
          currentError["table_per"] = false;
        }
        break;

      case "depth_per":
        if (value === "") {
          currentError["depth_per"] = "Required";
        } else {
          currentError["depth_per"] = false;
        }
        break;

      case "country":
        if (value === "") {
          currentError["country"] = "Required";
        } else {
          currentError["country"] = false;
        }
        break;

      case "length":
        if (value === "") {
          currentError["length"] = "Required";
        } else {
          currentError["length"] = false;
        }
        break;

      case "width":
        if (value === "") {
          currentError["width"] = "Required";
        } else {
          currentError["width"] = false;
        }
        break;

      case "height":
        if (value === "") {
          currentError["height"] = "Required";
        } else {
          currentError["height"] = false;
        }
        break;

      case "city":
        if (value === "") {
          currentError["city"] = "Required";
        } else {
          currentError["city"] = false;
        }
        break;

      case "growth_type":
        if (value === "") {
          currentError["growth_type"] = "Required";
        } else {
          currentError["growth_type"] = false;
        }
        break;

      default:
        break;
    }
  };

  const MyCustomCell = (props) => {
    const list = props.dataItem.reason.map((e, i) => {
      return (
        <div
          key={i}
          className="text-center bg-danger text-white mx-3 my-2 p-2"
          style={{
            borderRadius: "10px",
          }}
        >
          {e}
        </div>
      );
    });
    return <td>{list}</td>;
  };

  const myActionCell = (cell) => {
    return (
      <td>
        <div
          className={`d-flex justify-content-center aligh-items-center pointer`}
          onClick={() => {
            setIsOpen(true);
            setCurrentDetails(cell.dataItem);
            const currentError = error.find(
              (e) => parseInt(e.id) === cell.dataItem.id
            );
            setCurrentError(currentError);
            console.log("currentError", error);
          }}
        >
          <i className="fa-regular fa-eye"></i>
        </div>
      </td>
    );
  };

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={state}
      // dataItemKey={DATA_ITEM_KEY}
      headerCellRender={(props) => <th className="fs-semi bold">{props}</th>}
    >
      {/* <GridColumn field="uuid" title="UUID" width="100" /> */}
      <GridColumn field="id" title="Action" width="100" cell={myActionCell} />
      <GridColumn
        field="reason"
        title="Reason"
        width="200"
        cell={MyCustomCell}
      />
      <GridColumn field="stone_id" title="Stone Id" width="130" />
      {parseInt(diamondType) === 1 && (
        <GridColumn field="cert_no" title="Cert No" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="cert_type" title="Cert Type" width="130" />
      )}
      <GridColumn field="country" title="Country" width="130" />
      {parseInt(diamondType) === 2 && (
        <GridColumn field="city" title="City" width="130" />
      )}
      <GridColumn field="carat" title="Carat" width="130" />
      <GridColumn field="shape_id" title="Shape Id" width="130" />
      <GridColumn field="color_id" title="Color Id" width="130" />
      <GridColumn field="colors_id" title="Colors Id" width="130" />
      <GridColumn field="overtone_id" title="Overtone Id" width="130" />
      <GridColumn field="intensity_id" title="Intensity Id" width="130" />
      <GridColumn field="clarity_id" title="Clarity Id" width="130" />

      {parseInt(diamondType) === 1 && (
        <GridColumn field="polish_id" title="Polish Id" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="symmetry_id" title="Symmetry Id" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn
          field="fluorescence_id"
          title="Fluorescence Id"
          width="130"
        />
      )}
      <GridColumn field="rapo_rate" title="Rapo Rate" width="130" />
      <GridColumn field="discount" title="Discount" width="130" />
      <GridColumn field="rate" title="Rate" width="130" />
      <GridColumn field="amount" title="Amount" width="130" />
      {parseInt(diamondType) === 1 && (
        <GridColumn field="table_per" title="Table Per" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="depth_per" title="Depth Per" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="length" title="Length" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="width" title="Width" width="130" />
      )}
      {parseInt(diamondType) === 1 && (
        <GridColumn field="height" title="Height" width="130" />
      )}
      {parseInt(diamondType) === 2 && (
        <GridColumn field="growth_type" title="Growth Type" width="130" />
      )}
    </Grid>
  );

  useEffect(() => {
    if (props.saveFileReducer.success) {
      setIsOpen(false);
      setCurrentDetails(null);
      setCurrentError(null);
      props.invalidStoneList(diamondType);
      setLoading(true);
    }
  }, [props.saveFileReducer]);

  return (
    <>
      <div className="pt-5">
        <div className="page-top-line">
          <h2 className="page-title">
            Invalid Stone - <span className="fw-semi-bold">List</span>
          </h2>
        </div>
        <div className="row pt-4">
          <div className="col-md-6 col-12">
            <label className="form-label">
              <h4 className="">Diamond Type</h4>{" "}
            </label>
            <select
              className="form-select"
              value={diamondType}
              name="diamondType"
              onChange={(e) => {
                setDiamodType(e.target.value);
              }}
            >
              <option value={"1"}>Natural</option>
              <option value={"2"}>Lab Grown</option>
            </select>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid rgba(0,0,0)" }} />
        {loading ? <Loader /> : <>{grid}</>}
      </div>

      <Modal size="lg" show={isOpen} fullscreen={"lg-down"}>
        <Modal.Header>
          <Modal.Title>Update Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Stone Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="stone_id"
                    placeholder="Enter Stone Id"
                    value={
                      currentDetails?.stone_id !== "N/A"
                        ? currentDetails?.stone_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.stone_id && (
                    <span className="form-error">
                      {!!currentError && currentError.stone_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
              {parseInt(diamondType) === 1 && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Cert No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="cert_no"
                      placeholder="Enter Cert No."
                      value={
                        currentDetails?.cert_no !== "N/A"
                          ? currentDetails?.cert_no
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.cert_no && (
                      <span className="form-error">
                        {!!currentError && currentError.cert_no}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

            {parseInt(diamondType) === 1 && (
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Cert Type</Form.Label>
                    <Form.Control
                      type="text"
                      name="cert_type"
                      placeholder="Enter Cert type"
                      value={
                        currentDetails?.cert_type !== "N/A"
                          ? currentDetails?.cert_type
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.cert_type && (
                      <span className="form-error">
                        {!!currentError && currentError.cert_type}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Country</Form.Label>
                  <select
                    id="country"
                    name="country"
                    className="form-select"
                    value={
                      currentDetails?.country !== "N/A"
                        ? currentDetails?.country
                        : ""
                    }
                    onChange={handleInput}
                  >
                    <option value={""}>Select</option>
                    {countryList?.map((data, i) => {
                      return (
                        <option key={i} value={data.name}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {!!currentError && currentError.country && (
                    <span className="form-error">
                      {!!currentError && currentError.country}
                    </span>
                  )}
                </Form.Group>
              </Col>
              {parseInt(diamondType) === 2 && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Enter city"
                      value={
                        currentDetails?.city !== "N/A"
                          ? currentDetails?.city
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.city && (
                      <span className="form-error">
                        {!!currentError && currentError.city}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Carat</Form.Label>
                  <Form.Control
                    type="text"
                    name="carat"
                    placeholder="Enter carat"
                    value={
                      currentDetails?.carat !== "N/A"
                        ? currentDetails?.carat
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.carat && (
                    <span className="form-error">
                      {!!currentError && currentError.carat}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Shape Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="shape_id"
                    placeholder="Enter shape Id"
                    value={
                      currentDetails?.shape_id !== "N/A"
                        ? currentDetails?.shape_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.shape_id && (
                    <span className="form-error">
                      {!!currentError && currentError.shape_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Color Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="color_id"
                    placeholder="Enter color id"
                    value={
                      currentDetails?.color_id !== "N/A"
                        ? currentDetails?.color_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.color_id && (
                    <span className="form-error">
                      {!!currentError && currentError.color_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Colors Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="colors_id"
                    placeholder="Enter colors Id"
                    value={
                      currentDetails?.colors_id !== "N/A"
                        ? currentDetails?.colors_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.colors_id && (
                    <span className="form-error">
                      {!!currentError && currentError.colors_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Overtone Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="overtone_id"
                    placeholder="Enter overtone id"
                    value={
                      currentDetails?.overtone_id !== "N/A"
                        ? currentDetails?.overtone_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.overtone_id && (
                    <span className="form-error">
                      {!!currentError && currentError.overtone_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Intensity Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="intensity_id"
                    placeholder="Enter intensity Id"
                    value={
                      currentDetails?.intensity_id !== "N/A"
                        ? currentDetails?.intensity_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.intensity_id && (
                    <span className="form-error">
                      {!!currentError && currentError.intensity_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Clarity Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="clarity_id"
                    placeholder="Enter clarity id"
                    value={
                      currentDetails?.clarity_id !== "N/A"
                        ? currentDetails?.clarity_id
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.clarity_id && (
                    <span className="form-error">
                      {!!currentError && currentError.clarity_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
              {parseInt(diamondType) === 1 && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Polish Id</Form.Label>
                    <Form.Control
                      type="text"
                      name="polish_id"
                      placeholder="Enter polish Id"
                      value={
                        currentDetails?.polish_id !== "N/A"
                          ? currentDetails?.polish_id
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.polish_id_error && (
                      <span className="form-error">
                        {!!currentError && currentError.polish_id_error}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

            {parseInt(diamondType) === 1 && (
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Symmetry Id</Form.Label>
                    <Form.Control
                      type="text"
                      name="symmetry_id"
                      placeholder="Enter symmetry id"
                      value={
                        currentDetails?.symmetry_id !== "N/A"
                          ? currentDetails?.symmetry_id
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.symmetry_id_error && (
                      <span className="form-error">
                        {!!currentError && currentError.symmetry_id_error}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Fluorescence Id</Form.Label>
                    <Form.Control
                      type="text"
                      name="fluorescence_id"
                      placeholder="Enter fluorescence Id"
                      value={
                        currentDetails?.fluorescence_id !== "N/A"
                          ? currentDetails?.fluorescence_id
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.fluorescence_id && (
                      <span className="form-error">
                        {!!currentError && currentError.fluorescence_id}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Rapo Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="rapo_rate"
                    placeholder="Enter rapo rate"
                    value={
                      currentDetails?.rapo_rate !== "N/A"
                        ? currentDetails?.rapo_rate
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.rapo_rate && (
                    <span className="form-error">
                      {!!currentError && currentError.rapo_rate}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Discount</Form.Label>
                  <Form.Control
                    type="text"
                    name="discount"
                    placeholder="Enter Discount"
                    value={
                      currentDetails?.discount !== "N/A"
                        ? currentDetails?.discount
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.discount && (
                    <span className="form-error">
                      {!!currentError && currentError.discount}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="rate"
                    placeholder="Enter rate"
                    value={
                      currentDetails?.rate !== "N/A" ? currentDetails?.rate : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.rate && (
                    <span className="form-error">
                      {!!currentError && currentError.rate}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    placeholder="Enter amount"
                    value={
                      currentDetails?.amount !== "N/A"
                        ? currentDetails?.amount
                        : ""
                    }
                    onChange={handleInput}
                  />
                  {!!currentError && currentError.amount && (
                    <span className="form-error">
                      {!!currentError && currentError.amount}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {parseInt(diamondType) === 1 && (
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Table Per</Form.Label>
                    <Form.Control
                      type="text"
                      name="table_per"
                      placeholder="Enter table per"
                      value={
                        currentDetails?.table_per !== "N/A"
                          ? currentDetails?.table_per
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.table_per && (
                      <span className="form-error">
                        {!!currentError && currentError.table_per}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Depth Per</Form.Label>
                    <Form.Control
                      type="text"
                      name="depth_per"
                      placeholder="Enter depth_per"
                      value={
                        currentDetails?.depth_per !== "N/A"
                          ? currentDetails?.depth_per
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.depth_per && (
                      <span className="form-error">
                        {!!currentError && currentError.depth_per}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}

            {parseInt(diamondType) === 1 && (
              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Length</Form.Label>
                    <Form.Control
                      type="text"
                      name="length"
                      placeholder="Enter length"
                      value={
                        currentDetails?.length !== "N/A"
                          ? currentDetails?.length
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.length && (
                      <span className="form-error">
                        {!!currentError && currentError.length}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Width</Form.Label>
                    <Form.Control
                      type="text"
                      name="width"
                      placeholder="Enter width"
                      value={
                        currentDetails?.width !== "N/A"
                          ? currentDetails?.width
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.width && (
                      <span className="form-error">
                        {!!currentError && currentError.width}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Height</Form.Label>
                    <Form.Control
                      type="text"
                      name="height"
                      placeholder="Enter height"
                      value={
                        currentDetails?.height !== "N/A"
                          ? currentDetails?.height
                          : ""
                      }
                      onChange={handleInput}
                    />
                    {!!currentError && currentError.height && (
                      <span className="form-error">
                        {!!currentError && currentError.height}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}

            {parseInt(diamondType) === 2 && (
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Growth Type</Form.Label>
                    <select
                      id="growth_type"
                      name="growth_type"
                      className="form-select"
                      value={
                        currentDetails?.growth_type !== "N/A"
                          ? currentDetails?.growth_type
                          : ""
                      }
                      onChange={handleInput}
                    >
                      <option value={""}>Select Growth Type</option>
                      {!!masters &&
                        Object.keys(masters.Type).map((data, i) => {
                          return (
                            <option key={i} value={masters.Type[data]}>
                              {masters.Type[data]}
                            </option>
                          );
                        })}
                    </select>
                    {!!currentError && currentError.growth_type && (
                      <span className="form-error">
                        {!!currentError && currentError.growth_type}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpen(false);
                setCurrentDetails(null);
                setCurrentError(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                console.log("ss", {
                  details: [currentDetails],
                });
                // const _state = _.map(currentDetails, (e) => {
                //   return _.mapValues(e, (q) => (q === "N/A" ? "" : q));
                // });
                const _state = _.mapValues(currentDetails, (q) =>
                  q === "N/A" ? "" : q
                );
                props.saveCsvFile({
                  details: [_state],
                  id: _state.uuid,
                  diamond_type: diamondType,
                });

                // console.log("save", _state);
                // props.saveCsvFile({
                //   details: [currentDetails],
                //   id: currentDetails.uuid,
                //   diamond_type: props.diamondType,
                // });
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  invalidStoneReducer: state.masterListReducer.invalidStone,
  getCountryReducer: state.authReducer.getCountryList,
  saveFileReducer: state.masterListReducer.saveCsvFile,
  getAllMastersListReducer: state.masterListReducer.getAllMastersList,
});
const mapDispatchToProps = (dispatch) => ({
  invalidStoneList: (id) => dispatch(invalidStoneList(id)),
  getCountryList: (details) => dispatch(getCountryList(details)),
  saveCsvFile: (details) => dispatch(saveCsvFile(details)),
  getAllMastersList: () => dispatch(getAllMastersList()),
  resetList: (id) => dispatch({ type: INVALID_STONE_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidStone);
