import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { holdStoneList } from "../Services/Actions/masterListAction";
import { HOLD_LIST_RESET } from "../Services/Constant";
import Loader from "../Components/Loader";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import moment from "moment";

const DATA_ITEM_KEY = "id";
function HoldStone(props) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.holdStoneList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.holdStoneReducer.success) {
      const data = props.holdStoneReducer.data;
      const list = data.map((e) => {
        return {
          date: moment(e.updated_at).format("DD-MM-yyyy"),
          stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
          carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
          shape_id:
            !!e.shape_name && e.shape_name !== "" ? e.shape_name : "N/A",
          color_id:
            !!e.color_name && e.color_name !== "" ? e.color_name : "N/A",
          clarity_id:
            !!e.clarity_name && e.clarity_name !== "" ? e.clarity_name : "N/A",
          cut_id: !!e.cut_name && e.cut_name !== "" ? e.cut_name : "N/A",
          polish_id:
            !!e.polish_name && e.polish_name !== "" ? e.polish_name : "N/A",
          symmetry_id:
            !!e.symmetry_name && e.symmetry_name !== ""
              ? e.symmetry_name
              : "N/A",
          fluorescence_id:
            !!e.fluorescence_name && e.fluorescence_name !== ""
              ? e.fluorescence_name
              : "N/A",
          cert: !!e.cert && e.cert !== "" ? e.cert : "N/A",
          cert_no: !!e.cert_no && e.cert_no !== "" ? e.cert_no : "N/A",
          rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
          amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
        };
      });
      setState(list);

      setLoading(false);
    }
  }, [props.holdStoneReducer]);

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      headerCellRender={(props) => <th className="fs-semi bold">{props}</th>}
      data={state}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridColumn field="date" title="Date & Time" width="130" />
      {/* <GridColumn field="order_id" title="Order Id" width="130" /> */}
      <GridColumn field="stone_id" title="Stone Id" width="130" />
      <GridColumn field="carat" title="Carat" width="130" />
      <GridColumn field="shape_id" title="Shape" width="130" />
      <GridColumn field="color_id" title="Color" width="130" />
      <GridColumn field="clarity_id" title="Clarity" width="130" />
      <GridColumn field="cut_id" title="Cutd" width="130" />
      <GridColumn field="polish_id" title="Polish" width="130" />
      <GridColumn field="symmetry_id" title="Symmetry" width="130" />
      <GridColumn field="fluorescence_id" title="Fluorescence" width="130" />
      <GridColumn field="cert" title="Lab" width="130" />
      <GridColumn field="cert_no" title="Certificate" width="130" />
      <GridColumn field="rate" title="$/ct" width="130" />
      <GridColumn field="amount" title="Amount" width="130" />
    </Grid>
  );

  return (
    <div>
      <div className="page-top-line mt-5">
        <h2 className="page-title">
          Hold Stone - <span className="fw-semi-bold">List</span>
        </h2>
      </div>
      {loading ? <Loader /> : <div className="mt-4">{grid}</div>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  holdStoneReducer: state.masterListReducer.holdStone,
});
const mapDispatchToProps = (dispatch) => ({
  holdStoneList: () => dispatch(holdStoneList()),
  resetList: () => dispatch({ type: HOLD_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HoldStone);
