import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getStockList,
  resetDiamondList,
} from "../../Services/Actions/masterListAction";
import NaturalDiamondTable from "../../Components/NaturalDiamondTable";
import LabgrownDiamondTable from "../../Components/LabgrownDiamondTable";
import { Link } from "react-router-dom";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function UploadDiamonds(props) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectKey, setSelectKey] = useState("natural");

  useState(() => {
    props.getStockList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.resetDiamondReducer.success) {
      props.getStockList();
      setIsOpen(false);
    }
  }, [props.resetDiamondReducer]);

  useEffect(() => {
    setLoading(props.StockListReducer.loading);
    if (props.StockListReducer.success) {
      const data = props.StockListReducer.data;
      const list = data.map((e) => {
        return {
          stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
          cert_no: !!e.cert_no && e.cert_no !== "" ? e.cert_no : "N/A",
          cert: !!e.cert && e.cert !== "" ? e.cert : "N/A",
          status:
            !!e.status && e.status !== ""
              ? parseInt(e.status) === 1
                ? "Active"
                : "Deactive"
              : "N/A",
          id: !!e.id && e.id !== "" ? e.id : "N/A",
          diamond_type:
            !!e.diamond_type && e.diamond_type !== "" ? e.diamond_type : "N/A",
        };
      });
      setState(list);
      setLoading(false);
    }
  }, [props.StockListReducer]);
  console.log("state", state);

  return (
    <div>
      <div className="page-top-line mt-5">
        <h2 className="page-title">
          Upload Diamonds - <span className="fw-semi-bold">List</span>
        </h2>
      </div>
      <div className="text-end mb-4">
        <div
          className="btn btn-primary me-4"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Reset Diamond
        </div>
        <Link to="/upload-diamonds/add" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <div className="mt-4">
        <Tabs
          defaultActiveKey={selectKey}
          id="AddStockTabs"
          onSelect={(e) => setSelectKey(e)}
        >
          <Tab eventKey="natural" title="Natural Diamond List">
            <div className="card main-card border-0">
              <div className="card-body">
                <NaturalDiamondTable state={state} setState={setState} />
              </div>
            </div>
          </Tab>
          <Tab eventKey="lab-grown" title={"Lab-Grown Diamond List"}>
            <div className="card main-card border-0">
              <div className="card-body">
                <LabgrownDiamondTable state={state} setState={setState} />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(false);
          }}
        >
          Confirm Reset
        </ModalHeader>
        <ModalBody className="bg-white">
          Are you sure you want to reset this diamonds?
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.resetDiamondList(
                selectKey === "natural"
                  ? "1"
                  : selectKey === "lab-grown"
                  ? "2"
                  : 1
              );
            }}
          >
            Reset
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  StockListReducer: state.masterListReducer.StockList,
  resetDiamondReducer: state.masterListReducer.resetDiamond,
});
const mapDispatchToProps = (dispatch) => ({
  resetDiamondList: (details) => dispatch(resetDiamondList(details)),
  getStockList: () => dispatch(getStockList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDiamonds);
