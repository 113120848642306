import { DASHBOARD_ERROR, DASHBOARD_RESET, DASHBOARD_SUCCESS } from "../Constant";

let initialState = {
 dashboardDetails: {
  error: false,
  success: false,
  data: [],
 }
};



export default function dashboardReducer(state = initialState, action) {
 switch (action.type) {
  case DASHBOARD_SUCCESS:
   return {
    ...state,
    dashboardDetails: {
     ...state.dashboardDetails,
     success: true,
     error: false,
     data: action.data,
    },
   };

  case DASHBOARD_ERROR:
   return {
    ...state,
    dashboardDetails: {
     ...state.dashboardDetails,
     success: false,
     error: true,
    },
   };

  case DASHBOARD_RESET:
   return {
    ...state,
    dashboardDetails: { ...state.dashboardDetails, success: false, error: false },
   };


  default:
   return state;
 }
}