import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDashboardDetails } from "../Services/Actions/dashboardAction";
import Loader from "../Components/Loader";

function Dashboard(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    hold: 0,
    confirm: 0,
    active_stone: 0,
    invalid_stone: 0,
  });

  useEffect(() => {
    props.getDashboardDetails();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.dashboardDetailsReducer.success) {
      const data = props.dashboardDetailsReducer.data;
      setState(data);
      setLoading(false);
    }
  }, [props.dashboardDetailsReducer]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="content-block bg-transparent">
            <div className="row align-items-stre">
              <div className="col-xl-8">
                <div className="row">
                  <div className="col-xl-6 col-sm-6">
                    <Link to="/hold-stones" className="card dashblock-card">
                      <div className="card-body">
                        <h5 className="fw-semi-bold text-muted mb-2">
                          Hold Stones
                        </h5>
                        <h2 className="fw-bold mb-0">{state?.hold}</h2>
                        <span className="dashblock-icon">
                          <i
                            className="ri-hand-coin-line"
                            style={{ color: "#C2B362" }}
                          ></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xl-6 col-sm-6">
                    <Link to="/confirm-stones" className="card dashblock-card">
                      <div className="card-body">
                        <h5 className="fw-semi-bold text-muted mb-2">
                          Confirm Stone
                        </h5>
                        <h2 className="fw-bold mb-0">{state?.confirm}</h2>
                        <span className="dashblock-icon">
                          <i
                            className="ri-shield-check-line"
                            style={{ color: "#C2B362" }}
                          ></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xl-6 col-sm-6">
                    <Link to="/upload-diamonds" className="card dashblock-card">
                      <div className="card-body">
                        <h5 className="fw-semi-bold text-muted mb-2">
                          Active Stone
                        </h5>
                        <h2 className="fw-bold mb-0">{state?.active_stone}</h2>
                        <span className="dashblock-icon">
                          <i
                            className="ri-save-line"
                            style={{ color: "#C2B362" }}
                          ></i>
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <Link to="/invalid-stones" className="card dashblock-card">
                      <div className="card-body">
                        <h5 className="fw-semi-bold text-muted mb-2">
                          Invalid Stone
                        </h5>
                        <h2 className="fw-bold mb-0">{state?.invalid_stone}</h2>
                        <span className="dashblock-icon">
                          <i
                            className="ri-vip-diamond-line"
                            style={{ color: "#C2B362" }}
                          ></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  dashboardDetailsReducer: state.dashboardReducer.dashboardDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboardDetails: () => dispatch(getDashboardDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
