import React from "react";
import { Button, Container, FormGroup, Input } from "reactstrap";

function ErrorPage() {
  return (
    <div>
      <div className="errorPage">
        <Container>
          <div className="errorContainer mx-auto">
            <h1 className="errorCode">404</h1>
            <p className="errorInfo">
              Opps, it seems that this page does not exist.
            </p>
            <p className="errorHelp mb-3">
              If you are sure it should, search for it.
            </p>
            <div>
              <FormGroup>
                <Input
                  className="input-no-border"
                  type="text"
                  placeholder="Search Pages"
                />
              </FormGroup>
              <Button className="errorBtn" color="success">
                Search <i className="fa fa-search text-white ms-2" />
              </Button>
              {/* <Link to="app/extra/search">
          </Link> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ErrorPage;
